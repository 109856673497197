import { useFormState } from "react-final-form";
import { observer } from "mobx-react-lite";
import { Carusel } from "ui/Carusel";

import { useTranslation } from "react-i18next";
import { T } from "util/Translation";

import * as S from "./styles";

export const Gallery = observer(({ blockId }) => {
  const { t } = useTranslation();
  const values = useFormState()?.values?.[blockId]?.["tool-11"] || {};
  const gallery = values?.images || [];

  return (
    <S.Block>
      {gallery.length === 0 ? (
        <p>
          <T id="block.gallery.empty" />
        </p>
      ) : (
        <S.Gallery>
          <Carusel>
            {gallery.map((item, idx) => (
              <img
                key={idx}
                alt={t("block.gallery.alt_text")}
                src={item}
                height={`${values.height || 300}${
                  values.size === "percent" ? "%" : ""
                }`}
                width={`${values.width || 300}${
                  values.size === "percent" ? "%" : ""
                }`}
              />
            ))}
          </Carusel>
        </S.Gallery>
      )}
    </S.Block>
  );
});
