import { ContentPart } from "./ContentPart";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "assets/icons/logo_color.svg";
import { MAIN_URL } from "util/consts";

export const Footer = ({ data }) => {
  if (!data) return null;

  return (
    <footer id="footer">
      <div>
        {!!data && (
          <div className="ls-footer">
            <ContentPart fields={data.fields} />
          </div>
        )}
        <div className="ls-lumo-pages-footer">
          <div className="lp-logo">
            <span>Built with</span>
            <Link to={{ pathname: MAIN_URL }} target="_blank">
              <Logo height={32} width={88} />
            </Link>
          </div>
          <ul>
            <li>
              <p>© 2024 Lumo Pages </p>
            </li>
            <li>
              <Link
                to={{
                  pathname: `${MAIN_URL}user-created-pages-terms-of-use`,
                }}
                target="_blank"
              >
                Terms of Use
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: `${MAIN_URL}user-created-pages-privacy-policy`,
                }}
                target="_blank"
              >
                Pricavy Policy
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: `${MAIN_URL}user-created-pages-accessibility-statement`,
                }}
                target="_blank"
              >
                Accessibility Statement
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};
