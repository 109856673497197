import { observer } from "mobx-react-lite";

import { T } from "util/Translation";

import * as S from "./styles";

export const Statistics = observer(() => {
  return (
    <S.Wrapper>
      <div className="main-section">
        <div className="header">
          <h1>
            <T id="statistics.title" />
          </h1>
          <p>
            <T id="statistics.intro" />
          </p>
        </div>
        <div className="animated-blur-background">
          <span>
            <T id="statistics.coming_soon" />
          </span>
        </div>
      </div>
    </S.Wrapper>
  );
});
