import { Field, useFormState } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";
import { Radio } from "semantic-ui-react";
import * as S from "./styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const RadioGroup = ({ blockId }) => {
  const { t } = useTranslation();
  const values = useFormState()?.values?.[blockId]?.["tool-4"] || {};

  const [demoSelection, setDemoSelection] = useState("");

  const options = values.options || ["Example for option"];

  return (
    <S.Block>
      <Field name={`${blockId}.tool-4.field`} initialValue={values.field || ""}>
        {(props) => (
          <TextareaAutosize
            name={props.input.name}
            value={props.input.value}
            onChange={props.input.onChange}
            className="ls-contentEditable"
            placeholder={t("block.radio_group.field_placeholder")}
          />
        )}
      </Field>
      <Field name={`${blockId}.tool-4.desc`} initialValue={values.desc || ""}>
        {(props) => (
          <S.Field>
            <TextareaAutosize
              name={props.input.name}
              value={props.input.value}
              onChange={props.input.onChange}
              className="ls-contentEditable"
              placeholder={t("block.radio_group.desc_placeholder")}
            />
          </S.Field>
        )}
      </Field>

      <div>
        {options.map((option, idx) => (
          <div key={`${idx}-${option}`}>
            <Radio
              label={option}
              name={`${blockId}-preview`}
              value={option}
              checked={demoSelection === option}
              onChange={(e, data) => setDemoSelection(data.value)}
            />
          </div>
        ))}
      </div>
    </S.Block>
  );
};
