import { observer } from "mobx-react-lite";
import { Field, Form } from "react-final-form";
import { useState } from "react";

import { GeneralEdit, AsText } from "ui/common-styles";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Icon } from "assets/icons/Icon";
import { FormPreviewWrapper } from "./styles";
import { PanelActions } from "../../PanelActions";

import { useStore } from "structure";
import { BUILD_PARTS } from "util/consts";
import { useTranslation } from "react-i18next";
import { T } from "util/Translation";

export const BuildPart = observer(({ partId, project }) => {
  const { t } = useTranslation();
  const projectId = project.id;
  const { projectsStore } = useStore();
  const partSection = project.settings?.[BUILD_PARTS[partId].key];
  const [initialActive, setInitialActive] = useState(
    partSection?.isActive ? true : false
  );

  const onSubmit = async (data) => {
    console.log(data);

    const update = {
      [BUILD_PARTS[partId].key]: {
        content: partSection?.content || [],
        isActive: data.isActive,
      },
    };
    const res = await projectsStore.saveBuildContent(project, update);
    if (res) {
      setInitialActive(data.isActive);
      toast.success(
        t("build_part.save_success", { name: t(BUILD_PARTS[partId].name) })
      );
    }
  };

  const partFirstLetter = t(BUILD_PARTS[partId].name).substring(0, 1);

  return (
    <GeneralEdit>
      <h4>
        <T id={BUILD_PARTS[partId].name} />
      </h4>
      <p>
        <T id={BUILD_PARTS[partId].desc} />
      </p>

      <Form
        onSubmit={onSubmit}
        initialValues={{ isActive: initialActive }}
        render={({ handleSubmit, form, values }) => {
          const isVisible = values.isActive;
          return (
            <form onSubmit={handleSubmit}>
              <Field name="isActive" component="input" type="hidden" />
              <FormPreviewWrapper singlePart isVisible={isVisible}>
                <Link
                  className="ls-form-info"
                  to={`/project/${projectId}/part/${BUILD_PARTS[partId].key}`}
                >
                  <div>
                    <div className="ls-icon-circle-primary">
                      <span>{partFirstLetter}</span>
                    </div>
                    <div className="ls-info">
                      <p className="ls-name">
                        <T id={BUILD_PARTS[partId].name} />
                      </p>
                    </div>
                  </div>
                  <div>
                    <div
                      className={
                        "ls-stage-tag " +
                        (isVisible ? "ls-stage-tag-on" : "ls-stage-tag-off")
                      }
                    >
                      {isVisible ? (
                        <T id="build_part.active" />
                      ) : (
                        <T id="build_part.disabled" />
                      )}
                    </div>
                    <div className="ls-icon-circle-go">
                      <Icon name="right" />
                    </div>
                  </div>
                </Link>

                <div className="ls-form-actions">
                  <AsText
                    type="button"
                    onClick={() => form.change("isActive", !isVisible)}
                  >
                    {isVisible ? (
                      <T id="build_part.hide" />
                    ) : (
                      <T id="build_part.show" />
                    )}{" "}
                    <Icon name={isVisible ? "visibilityOff" : "visibility"} />
                  </AsText>
                </div>
              </FormPreviewWrapper>

              <div style={{ marginTop: 16 }} />
              <PanelActions />
            </form>
          );
        }}
      />
    </GeneralEdit>
  );
});
