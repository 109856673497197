import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "structure";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { Form as FormUI, Button } from "semantic-ui-react";
import { Form } from "react-final-form";
import { FieldText } from "ui/FieldText";
import { multiValidators, required, min, max } from "util/validators";
import { T } from "util/Translation";

export const EmailVerification = observer(() => {
  const { t } = useTranslation();
  const { accountStore } = useStore();
  const history = useHistory();
  const { loginDetails } = accountStore;
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    if (seconds > 0) {
      const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [seconds]);

  useEffect(() => {
    if (!loginDetails) {
      history.push("/login");
    }
  }, [loginDetails, history]);

  const handleResendEmail = async () => {
    const res = await accountStore.requestVerifyEmail(loginDetails.email);
    if (res.error) {
      return res;
    } else {
      setSeconds(60);
      toast.success(t("email_verification.verification_code_sent"));
    }
  };

  const submit = async (data) => {
    const res = await accountStore.verifyEmail({
      email: loginDetails.email,
      code: data.code,
    });
    if (res.error) {
      return { code: res.error };
    }
    history.push("/");
  };

  return (
    <div className="ls-outlet-wrapper">
      <div className="ls-confirm">
        <h1>
          <T id="email_verification.title" />
        </h1>
        <div className="ls-body">
          <div className="ls-section">
            <p className="ls-email">
              <T
                id="email_verification.code_sent_to"
                values={{ email: loginDetails.email }}
                components={{ strong: <strong /> }}
              />
            </p>
            <div className="ls-resend">
              <p>
                <T id="email_verification.didnt_receive" />
              </p>
              <Button
                type="button"
                basic
                onClick={handleResendEmail}
                disabled={seconds > 0}
              >
                {seconds > 0
                  ? t("email_verification.wait_seconds", { seconds })
                  : t("email_verification.send_new_code")}
              </Button>
            </div>
          </div>
          <div className="ls-section">
            <Form
              onSubmit={submit}
              render={({ handleSubmit, submitting }) => (
                <FormUI onSubmit={handleSubmit}>
                  <FieldText
                    finalFormProps={{
                      name: "code",
                      validate: multiValidators([required, min(6), max(6)]),
                    }}
                    semanticProps={{
                      disabled: submitting,
                    }}
                    label={t("email_verification.enter_code")}
                  />
                  <Button
                    primary
                    type="submit"
                    disabled={submitting}
                    loading={submitting}
                    fluid
                  >
                    {t("email_verification.verify_button")}
                  </Button>
                </FormUI>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
