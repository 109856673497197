import { Button, Modal } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { ModalCloseWrapper, ModalContentWrapper } from "ui/common-styles";
import { Icon } from "assets/icons/Icon";

import { useStore } from "structure";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import * as S from "./styles";
import { T } from "util/Translation";

const LANGS = [
  {
    id: "en",
    text: "English",
  },
  {
    id: "he",
    text: "עברית",
  },
];

export const LangModal = observer(() => {
  const { i18n, t } = useTranslation();
  const { accountStore } = useStore();
  const { isLangModalOpen } = accountStore;
  const [selection, setSelection] = useState(i18n.resolvedLanguage);

  const closeModal = () => accountStore.setIsLangModalOpen(false);

  const applyChangesHandler = () => {
    i18n.changeLanguage(selection);
    closeModal();
  };

  return (
    <Modal
      size="tiny"
      onClose={closeModal}
      open={isLangModalOpen}
      closeIcon={
        <ModalCloseWrapper>
          <Icon name="close" />
        </ModalCloseWrapper>
      }
    >
      <ModalContentWrapper>
        <div className="ls-content">
          <h3>{t("change_lang_modal.title")}</h3>
          <p>
            <T
              id={"change_lang_modal.description"}
              components={{ br: <br /> }}
            />
          </p>
          <S.LangOptions>
            {LANGS.map((lang) => (
              <Button
                key={lang.id}
                fluid
                color={selection === lang.id ? "green" : "grey"}
                className="ls-bright"
                onClick={() => setSelection(lang.id)}
              >
                {lang.text}
              </Button>
            ))}
          </S.LangOptions>
        </div>

        <div className="ls-actions">
          <Button
            type="submit"
            className="ls-bright"
            primary
            content={t("change_lang_modal.apply")}
            onClick={applyChangesHandler}
          />
        </div>
      </ModalContentWrapper>
    </Modal>
  );
});
