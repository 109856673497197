import { Field, useFormState } from "react-final-form";
import { Input } from "semantic-ui-react";
import TextareaAutosize from "react-textarea-autosize";
import { useTranslation } from "react-i18next";
import { formatSystemDate } from "util/helpers";
import * as S from "./styles";

export const DateEl = ({ blockId }) => {
  const { t } = useTranslation();
  const values = useFormState()?.values?.[blockId]?.["tool-7"] || {};

  const now = new Date();
  const minDatePreview =
    values.minDate === "none"
      ? null
      : values.minDate === "current"
      ? formatSystemDate(now, values.type)
      : formatSystemDate(values.minDatePicker, values.type);
  const maxDatePreview =
    values.maxDate === "none"
      ? null
      : values.maxDate === "current"
      ? formatSystemDate(now, values.type)
      : formatSystemDate(values.maxDatePicker, values.type);

  return (
    <S.Block>
      <Field name={`${blockId}.tool-7.field`} initialValue={values.field || ""}>
        {(props) => (
          <TextareaAutosize
            name={props.input.name}
            value={props.input.value}
            onChange={props.input.onChange}
            className="ls-contentEditable"
            placeholder={t("block.dateEl.field_placeholder")}
          />
        )}
      </Field>
      <Field name={`${blockId}.tool-7.desc`} initialValue={values.desc || ""}>
        {(props) => (
          <S.Field>
            <TextareaAutosize
              name={props.input.name}
              value={props.input.value}
              onChange={props.input.onChange}
              className="ls-contentEditable"
              placeholder={t("block.dateEl.desc_placeholder")}
            />
          </S.Field>
        )}
      </Field>

      <S.Field className="pickerField">
        <Input
          type={values.type || "date"}
          min={minDatePreview}
          max={maxDatePreview}
        />
      </S.Field>
    </S.Block>
  );
};
