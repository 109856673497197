export const MAIN_URL = "https://lumopages.com/";
export const API_URL = "https://api.lumopages.com/";
export const APP_URL = "https://app.lumopages.com/";
export const LIVE_URL = "https://live.lumopages.com/";

export const DISPLAY_TOOLS = [
  {
    id: 14,
    name: "display_tools.rich_text.name",
    desc: "display_tools.rich_text.desc",
    icon: "textIcon",
  },
  {
    id: 10,
    name: "display_tools.image.name",
    desc: "display_tools.image.desc",
    label: true,
    labelDesc: true,
    icon: "imageIcon",
  },

  {
    id: 12,
    name: "display_tools.video.name",
    desc: "display_tools.video.desc",
    label: true,
    labelDesc: false,
    icon: "videoIcon",
  },

  {
    id: 11,
    name: "display_tools.gallery.name",
    desc: "display_tools.gallery.desc",
    label: true,
    labelDesc: true,
    icon: "galleryIcon",
  },

  // CURRENTLY NOT AVAILABLE
  // {
  //   id: 13,
  //   name: "layout",
  //   desc: "orgenize your layout",
  //   icon: "splitRowIcon",
  // },
  // {
  //   id: 1,
  //   name: "title",
  //   desc: "add text",
  //   label: true,
  //   labelDesc: true,
  //   icon: "titleIcon",
  // },
];

export const FORM_TOOLS = [
  {
    id: 2,
    name: "display_tools.text.name",
    desc: "display_tools.text.desc",
    label: true,
    labelDesc: true,
    types: ["text", "number", "email"],
    allowRequiered: true,
    icon: "textIcon",
  },
  {
    id: 3,
    name: "display_tools.textarea.name",
    desc: "display_tools.textarea.desc",
    lines: 4,
    label: true,
    labelDesc: true,
    allowRequiered: true,
    icon: "textAreaIcon",
  },
  {
    id: 4,
    name: "display_tools.radio.name",
    desc: "display_tools.radio.desc",
    label: true,
    labelDesc: true,
    allowRequiered: true,
    icon: "radioIcon",
  },
  {
    id: 5,
    name: "display_tools.dropdown.name",
    desc: "display_tools.dropdown.desc",
    label: true,
    labelDesc: true,
    types: ["single", "multiple"],
    allowRequiered: true,
    icon: "dropdownIcon",
  },
  {
    id: 6,
    name: "display_tools.checkbox.name",
    desc: "display_tools.checkbox.desc",
    label: true,
    labelDesc: true,
    types: ["box", "toggle"],
    allowRequiered: true,
    icon: "checkboxIcon",
  },
  {
    id: 7,
    name: "display_tools.date.name",
    desc: "display_tools.date.desc",
    label: true,
    labelDesc: true,
    types: ["date", "time", "datetime"],
    allowRequiered: true,
    icon: "dateIcon",
  },
];

export const TOOLS = [...DISPLAY_TOOLS, ...FORM_TOOLS];

export const BUILD_PARTS = {
  header: {
    key: "header",
    name: "build_parts.header.name",
    desc: "build_parts.header.desc",
  },
  footer: {
    key: "footer",
    name: "build_parts.footer.name",
    desc: "build_parts.footer.desc",
  },
  success: {
    key: "success",
    name: "build_parts.success.name",
    desc: "build_parts.success.desc",
  },
  error: {
    key: "error",
    name: "build_parts.error.name",
    desc: "build_parts.error.desc",
  },
};

export const PLANS = {
  premiumAnnualy: {
    id: "premiumAnnualy",
    name: "plans.premium.name",
    type: "plans.annualy",
    price: 12,
    pp: "P-7NC12994TU026215TMYPNC6Q",
  },

  premiumMonthly: {
    id: "premiumMonthly",
    name: "plans.premium.name",
    type: "plans.monthly",
    price: 14,
    pp: "P-2XN16748SF880143DM2SWHSA",
  },

  proAnnualy: {
    id: "proAnnualy",
    name: "plans.pro.name",
    type: "plans.annualy",
    price: 32,
    pp: "P-7SU22758AC817583DMYPNDTQ",
  },

  proMonthly: {
    id: "proMonthly",
    name: "plans.pro.name",
    type: "plans.monthly",
    price: 36,
    pp: "P-1E239733HE683381TMYPNDHY",
  },
};
