import { API_URL } from "util/consts";

const createHeaders = (isFile = false) => {
  const token = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
  };

  if (!isFile) {
    headers["Content-Type"] = "application/json";
  }

  return headers;
};

export const GET = async (endpoint, params) => {
  let paramsUri = "";

  if (params) {
    paramsUri =
      "?" +
      Object.entries(params)
        .map(([key, value]) => {
          if (Array.isArray(value)) {
            return value.map((item) => `${key}[]=${item}`).join("&");
          }
          return `${key}=${value}`;
        })
        .join("&");
  }
  return fetch(`${API_URL}${endpoint}/${paramsUri}`, {
    method: "GET",
    headers: createHeaders(),
  }).then((response) => response.json());
};

export const POST = async (endpoint, body = {}, isFile = false) => {
  return fetch(`${API_URL}${endpoint}`, {
    method: "POST",
    headers: createHeaders(isFile),
    body: isFile ? body : JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.status === "success") {
        return response.data;
      } else {
        return response;
      }
    });
};

export const PUT = async (endpoint, id, body) => {
  return fetch(`${API_URL}${endpoint}/${id || ""}`, {
    method: "PUT",
    headers: createHeaders(),
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.status === "success" || response.data) {
        return response.data;
      } else {
        console.error(response);
      }
    });
};

export const DELETE = (endpoint, id) => {
  return fetch(`${API_URL}${endpoint}/${id}`, {
    method: "DELETE",
    headers: createHeaders(),
  });
};
