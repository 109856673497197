import * as S from "./styles";
import { calcSize } from "util/helpers";

export const Image = ({ data }) => {
  const [height, width] = calcSize(data);
  return (
    <S.Block>
      {data.desc && <p>{data.desc}</p>}
      <S.Image>
        <img
          src={data.image[0]}
          height={height}
          width={width}
          alt={data.desc || "Display image"}
        />
      </S.Image>
    </S.Block>
  );
};
