import { Text as TextElement } from "./Elements/Text";
import { TextArea as TextAreaElement } from "./Elements/TextArea";
import { RadioGroup as RadioGroupElement } from "./Elements/RadioGroup";
import { Dropdown as DropdownElement } from "./Elements/Dropdown";
import { Checkbox as CheckboxElement } from "./Elements/Checkbox";
import { DateEl as DateElement } from "./Elements/Date";
import { Image as ImageElement } from "./Elements/Image";
import { Gallery as GalleryElement } from "./Elements/Gallery";
import { Video as VideoElement } from "./Elements/Video";
import { RichText as RichTextElement } from "./Elements/RichText";
import { useStore } from "structure";
import { useForm } from "react-final-form";
import * as S from "./styles";

export const Block = ({ type, blockId }) => {
  const { change, getState } = useForm();
  const { values } = getState();
  const { formEditorStore } = useStore();

  const initialValues = values?.[blockId]?.[type] || {};

  console.log(initialValues);

  if (Object.keys(initialValues).length > 0) {
    change(blockId, {
      [type]: initialValues,
    });
  }

  const handleClick = () => {
    formEditorStore.setBlockEditId(blockId);
  };

  return (
    <S.Wrapper key={blockId} onClick={handleClick}>
      <S.Block>
        {type === "tool-2" && <TextElement blockId={blockId} />}
        {type === "tool-3" && <TextAreaElement blockId={blockId} />}
        {type === "tool-4" && <RadioGroupElement blockId={blockId} />}
        {type === "tool-5" && <DropdownElement blockId={blockId} />}
        {type === "tool-6" && <CheckboxElement blockId={blockId} />}
        {type === "tool-7" && <DateElement blockId={blockId} />}
        {type === "tool-10" && <ImageElement blockId={blockId} />}
        {type === "tool-11" && <GalleryElement blockId={blockId} />}
        {type === "tool-12" && <VideoElement blockId={blockId} />}
        {type === "tool-14" && <RichTextElement blockId={blockId} />}
      </S.Block>
    </S.Wrapper>
  );
};
