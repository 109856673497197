import styled from "styled-components";
import { hiddenScroll } from "ui/common-styles";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  .active-drag {
    > div:last-child {
      transition: 0.3s;
      border-radius: 2px;
      box-shadow: 0px 4px 16px #00000018;
      outline: 1px solid var(--secondary-700);
    }
  }
`;

export const EditorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const ToolsWrapper = styled.div`
  height: calc(100vh - 56px);
  width: 209px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  padding: 16px 6px 16px 16px;
  gap: 16px;
  border-right: 1px solid var(--grey-200);
  align-items: flex-start;
  background: var(--white);
  overflow-y: scroll;
  overflow-x: clip;
  position: sticky;
  top: 56px;

  > h4 {
    line-height: 40px;
  }

  .ls-tool-section {
    width: 100%;
    border-top: 1px solid var(--grey-300);
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    > h6 {
      font-family: var(--primary-font);
    }
    > div {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
      align-content: flex-start;
      align-items: center;
    }
  }

  .tool {
    height: 92px;
    width: 80px;
    margin: 0;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    background: var(--grey-50);
    border: 1px solid var(--grey-200);
    border-radius: 8px;
    fill: var(--grey-500);
    p {
      color: var(--grey-600);
      text-transform: capitalize;
      font-family: var(--primary-font);
      line-height: 1;
      font-weight: 600;
      font-size: 14px;
    }
    &:hover p {
      color: var(--grey-700);
    }

    &.tool-active {
      background: var(--primary-50);
      border: 1px solid var(--primary-600);
      fill: var(--primary-500);
      box-shadow: 0px 4px 8px #06b6d411;
      p {
        color: var(--primary-600);
      }
      &:hover p {
        color: var(--primary-700);
      }
    }
  }

  ${hiddenScroll}
`;

export const PanelWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .ls-actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    ul {
      display: flex;
      list-style-type: none;
      gap: 16px;
      margin: 0px;
      border-bottom: 1px solid var(--grey-500);
      padding: 0 8px 8px;
      margin-bottom: -8px;
    }
  }
  .ls-form-title {
    width: calc(100% - 32px);
    max-width: 800px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 8px;
    height: 50px;
    margin: 0 auto;

    > form {
      display: flex;
    }
    h1 {
      font-weight: 700;
      color: var(--primary-500);
      font-size: 32px;
    }
    svg {
      fill: var(--grey-700);
    }

    form {
      width: 100%;
      display: grid;
      grid-template-columns: calc(100% - 152px) 152px;
      margin-bottom: -32px;
    }

    .ui.input > input {
      font-weight: 700;
      color: var(--primary-500);
      line-height: 1.2;
      font-size: 32px;
      font-family: var(--title-font);
      padding: 0;
      background: var(--grey-100);
      border: none;
      border-radius: 0;
      border-bottom: 1px solid var(--grey-400);
      margin-bottom: 16px;
    }

    .ls-form-name-actions {
      display: flex;
      gap: 8px;
      animation: 0.6s fadeIn;
      align-items: center;
      margin-bottom: 16px;
    }
  }
`;

export const PageWrapper = styled.form`
  height: calc(100% - 84px);
  width: calc(100% - 32px);
  max-width: 800px;
  margin: 16px auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  background: white;
  border-radius: 8px;
  background: var(--grey-50);
  border: 1px solid var(--grey-300);

  .ls-blocks {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px 0 16px;

    > .block {
      padding: 16px;

      &.ls-focused {
        //background: var(--tertiary-50);
      }
    }
  }
  .ls-ops {
    height: 60px;
    width: 100%;
    display: flex;
    gap: 16px;
    background: #947676;
    align-items: center;
    justify-content: center;
  }

  .block .block-drag + * {
    border-radius: 2px;
    box-shadow: -4px 0px 0px 0px transparent;
    transition: 0.2s;
  }

  .block.ls-focused .block-drag + * {
    border-radius: 2px;
    box-shadow: -4px 0px 0px 0px var(--tertiary-500);
  }

  .block:hover .block-drag button {
    visibility: visible;
  }

  .block-drag {
    height: 0;
    button {
      visibility: hidden;
      position: relative;
      background: transparent;
      color: var(--grey-900);
      border-radius: 2px;
      border: none;
      padding: 8px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 16px;
      cursor: grab;
      fill: var(--secondary-500);
    }
  }
`;

export const MainFormEditing = styled.div`
  width: calc(100% - 209px);
  max-width: 100%;
  min-width: 760px;
  display: grid;
  grid-template-columns: calc(100% - 360px) 360px;
  justify-content: space-around;
  justify-items: center;

  .ls-main-editor {
    width: 100%;
    min-width: 400px;
  }
`;
