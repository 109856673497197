import { Field, useFormState } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";
import { Dropdown as SemanticDropdown } from "semantic-ui-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./styles";

export const Dropdown = ({ blockId }) => {
  const { t } = useTranslation();
  const values = useFormState()?.values?.[blockId]?.["tool-5"] || {};

  const options = useMemo(
    () =>
      values.options?.map((op, idx) => ({
        key: `${op}-${idx}`,
        text: op,
        value: op,
      })) || [],
    [values.options]
  );

  return (
    <S.Block>
      <Field name={`${blockId}.tool-5.field`}>
        {(props) => (
          <TextareaAutosize
            name={props.input.name}
            value={props.input.value}
            onChange={props.input.onChange}
            className="ls-contentEditable"
            placeholder={t("block.dropdown.field_placeholder")}
          />
        )}
      </Field>
      <Field name={`${blockId}.tool-5.desc`}>
        {(props) => (
          <S.Field>
            <TextareaAutosize
              name={props.input.name}
              value={props.input.value}
              onChange={props.input.onChange}
              className="ls-contentEditable"
              placeholder={t("block.dropdown.desc_placeholder")}
            />
          </S.Field>
        )}
      </Field>

      <SemanticDropdown
        name={`${blockId}-preview`}
        fluid
        search
        selection
        multiple={values.selection === "multi"}
        options={options}
        clearable
      />
    </S.Block>
  );
};
