import { Button, Modal } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { useStore } from "structure";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Icon } from "assets/icons/Icon";
import { FieldText } from "ui/FieldText";
import { ModalCloseWrapper, ModalContentWrapper } from "ui/common-styles";
import { T } from "util/Translation";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

export const CreateProjectModal = observer(({ open, setOpen }) => {
  const { t } = useTranslation();
  const { projectsStore } = useStore();
  const history = useHistory();

  const onSubmit = async (data) => {
    const res = await projectsStore.create(data.name, data.public);
    setOpen(false);
    if (res?.data?.id) {
      history.push(`/project/${res.data.id}`);
    } else {
      toast.error(t("error.generic_error"));
    }
  };

  return (
    <Modal
      size="tiny"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      closeIcon={
        <ModalCloseWrapper>
          <Icon name="close" />
        </ModalCloseWrapper>
      }
    >
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ModalContentWrapper>
              <div className="ls-content">
                <h3>
                  <T id="create_project.title" />
                </h3>
                <p>
                  <T id="create_project.intro" />
                </p>
              </div>
              <div className="ls-mark">
                <FieldText
                  label={t("create_project.enter_name")}
                  finalFormProps={{ name: "name" }}
                  semanticProps={{
                    placeholder: t("create_project.placeholder_name"),
                  }}
                />
              </div>
              <div className="ls-actions">
                <Button
                  type="submit"
                  className="ls-bright"
                  primary
                  content={t("create_project.create_button")}
                />
                <Button
                  type="button"
                  className="ls-bright"
                  color="grey"
                  content={t("create_project.cancel_button")}
                  onClick={() => setOpen(false)}
                />
              </div>
            </ModalContentWrapper>
          </form>
        )}
      />
    </Modal>
  );
});
