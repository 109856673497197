import { Field } from "react-final-form";
import { FormField } from "./common-styles";
import { Dropdown } from "semantic-ui-react";

export const FieldDropdown = ({ label, finalFormProps, semanticProps }) => {
  const { fluid, selection, defaultValue, ...otherSemanticProps } =
    semanticProps;

  const initValue = defaultValue || otherSemanticProps.multiple ? [] : "";
  return (
    <Field {...finalFormProps}>
      {({ meta, input }) => {
        const hasError =
          meta.touched &&
          (meta.error || (meta.submitError && !meta.modifiedSinceLastSubmit));

        return (
          <FormField>
            <label>{label}</label>
            <Dropdown
              name={input.name}
              value={input.value || initValue}
              onChange={(e, data) => {
                input.onChange(data.value);
              }}
              error={hasError ? meta.error || meta.submitError : null}
              fluid={fluid === undefined ? true : fluid}
              selection={selection === undefined ? true : selection}
              {...otherSemanticProps}
            />
            {hasError && (
              <p className="ls-error">{meta.error || meta.submitError}</p>
            )}
          </FormField>
        );
      }}
    </Field>
  );
};
