import { useState } from "react";
import { useStore } from "structure";
import { useHistory, Link } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { Form } from "react-final-form";
import { FieldText } from "ui/FieldText";
import { FieldCheckbox } from "ui/FieldCheckbox";
import { Form as FormUI, Button, Divider } from "semantic-ui-react";
import { min, max, multiValidators, required } from "util/validators";
import { useTranslation } from "react-i18next";
import { T } from "util/Translation";

import { FORM_ERROR } from "final-form";

import * as S from "../styles";
import { MAIN_URL } from "util/consts";

export const Login = () => {
  const { t } = useTranslation();
  const { accountStore } = useStore();
  const history = useHistory();
  const [isAdditionalInfoRequired, setIsAdditionalInfoRequired] =
    useState(false);
  const [additionalInfo, setAdditionalInfo] = useState({
    email: "",
    name: "",
    google_id: "",
    avatar: "",
    username: "",
  });

  const handleLogin = async (data) => {
    const res = await accountStore.login(data);
    if (res.status === "error") {
      return { [FORM_ERROR]: t("login.error_invalid_credentials") };
    }
    if (res.status === "require_email_verification") {
      history.push("/email-verification");
    } else {
      history.push("/");
    }
  };

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    const res = await accountStore.loginGoogle(credentialResponse.credential);
    if (res.status === "additional_info_required") {
      setIsAdditionalInfoRequired(true);
      setAdditionalInfo({
        email: res.email,
        name: res.name,
        google_id: res.google_id,
        avatar: res.avatar,
        username: "",
      });
    }
    if (res.user) history.push("/");
  };

  return (
    <>
      <div className="ls-outlet-wrapper">
        <div className="ls-login">
          {isAdditionalInfoRequired ? (
            <AdditionalInfoRequired info={additionalInfo} />
          ) : (
            <>
              <h1>
                <T id="login.sign_in_title" />
                <span>
                  <T id="login.sign_in_subtitle" />
                </span>
              </h1>

              <div className="ls-body">
                <div className="ls-section-center">
                  <GoogleLogin
                    onSuccess={handleGoogleLoginSuccess}
                    onError={() => {
                      console.error("Login Failed");
                    }}
                  />
                </div>
                <Divider horizontal>
                  <T id="login.or_continue_with_email" />
                </Divider>
                <div className="ls-section">
                  <Form
                    onSubmit={handleLogin}
                    render={({
                      handleSubmit,
                      submitting,
                      submitError,
                      hasSubmitErrors,
                    }) => {
                      return (
                        <FormUI onSubmit={handleSubmit}>
                          {hasSubmitErrors && (
                            <div className="ls-submit-error">
                              <p>{submitError}</p>
                            </div>
                          )}

                          <FieldText
                            type="email"
                            finalFormProps={{
                              name: "email",
                              validate: multiValidators([required]),
                            }}
                            semanticProps={{
                              disabled: submitting,
                              autoComplete: "current-email",
                            }}
                            label={<T id="login.email_label" />}
                          />
                          <FieldText
                            finalFormProps={{
                              name: "password",
                              validate: multiValidators([
                                required,
                                min(8),
                                max(255),
                              ]),
                            }}
                            semanticProps={{
                              type: "password",
                              disabled: submitting,
                              autoComplete: "current-password",
                            }}
                            label={
                              <div className="ls-split">
                                <label>
                                  <T id="login.password_label" />
                                </label>
                                <Link to="/reset-password">
                                  <T id="login.forgot_password" />
                                </Link>
                              </div>
                            }
                          />
                          <FieldCheckbox
                            label={<T id="login.remember_me" />}
                            finalFormProps={{
                              name: "remember",
                            }}
                            semanticProps={{
                              disabled: submitting,
                            }}
                          />

                          <Button
                            type="submit"
                            primary
                            fluid
                            disabled={submitting}
                            loading={submitting}
                          >
                            {t("login.submit_button")}
                          </Button>
                        </FormUI>
                      );
                    }}
                  />
                </div>

                <p className="ls-disclaimer">
                  <T id="login.agreement_text" />{" "}
                  <Link
                    to={{ pathname: `${MAIN_URL}terms-of-use/` }}
                    target="_blank"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <T id="login.terms_of_use" />
                  </Link>
                  <br />
                  <T id="login.and_acknowledge_privacy" />{" "}
                  <Link
                    to={{ pathname: `${MAIN_URL}privacy-policy/` }}
                    target="_blank"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <T id="login.privacy_policy" />
                  </Link>
                </p>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="ls-message">
        <p>
          <T id="login.no_account" />
        </p>{" "}
        <Link to="/register">
          <T id="login.register" />
        </Link>
      </div>
    </>
  );
};

export const AdditionalInfoRequired = ({ info }) => {
  const { t } = useTranslation();
  const { accountStore } = useStore();
  const history = useHistory();

  const submitCompleteRegistration = async (data) => {
    if (await accountStore.registerWithGoogleData({ ...info, ...data })) {
      history.push("/");
    }
  };

  return (
    <Form
      onSubmit={submitCompleteRegistration}
      render={({ handleSubmit, submitting }) => (
        <FormUI onSubmit={handleSubmit}>
          <S.GoogleAccountWrapper>
            <img src={`/media/${info.avatar}`} alt="avatar" />
            <div className="ls-account-content">
              <span>{info.email}</span>
            </div>
          </S.GoogleAccountWrapper>
          <FieldText
            finalFormProps={{
              name: "name",
              validate: multiValidators([required, min(2)]),
              initialValue: info.name,
            }}
            semanticProps={{
              disabled: submitting,
            }}
            label={<T id="login.verify_name" />}
          />

          <FieldText
            finalFormProps={{
              name: "username",
              validate: multiValidators([required, min(4)]),
            }}
            semanticProps={{
              disabled: submitting,
            }}
            label={t("login.select_username")}
          />

          <Button
            primary
            type="submit"
            disabled={submitting}
            loading={submitting}
            fluid
          >
            {t("login.complete_registration_button")}
          </Button>
        </FormUI>
      )}
    />
  );
};
