import { observer } from "mobx-react-lite";
import { useStore } from "structure";
import { Link } from "react-router-dom";
import { useMemo } from "react";

import { Loading } from "components/shared/Loading";
import { Button } from "semantic-ui-react";
import { Icon } from "assets/icons/Icon";
import {
  FormField,
  PaymentSummaryWrapper,
  PlanOptionsWrapper,
  TopMenu,
} from "ui/common-styles";
import { SubscriptionCard } from "../../sections/Billing";

import { T } from "util/Translation";
import { required } from "util/validators";
import { Field, Form } from "react-final-form";
import { addOneMonth, addOneYear, formatDate } from "util/helpers";

import * as S from "./styles";

import { PLANS } from "util/consts";
import { useTranslation } from "react-i18next";

const plans = PLANS;

export const Upgrade = observer(() => {
  const { accountStore, paymentsStore } = useStore();
  const { currentAccount, isLoading } = accountStore;

  const upgradePlans = useMemo(() => {
    let res = [];
    if (
      !currentAccount.subscription_type ||
      currentAccount.subscription_type === "BASIC"
    ) {
      res = [
        plans.premiumAnnualy,
        plans.premiumMonthly,
        plans.proAnnualy,
        plans.proMonthly,
      ];
    } else {
      const month = isDateWithinMonth(currentAccount.expiration_date);
      if (currentAccount.subscription_type === "PRO" && month) {
        res.push(plans.proAnnualy);
      } else if (currentAccount.subscription_type === "PREMIUM") {
        if (month) {
          res.push(plans.premiumAnnualy);
          res.push(plans.proMonthly);
        }
        res.push(plans.proAnnualy);
      }
    }
    return res;
  }, [currentAccount.subscription_type, currentAccount.expiration_date]);

  const onSubmit = async (data) => {
    const planId = plans[data.plan]?.pp;

    const res = await paymentsStore.subscribe({
      planId,
      currentAccount,
    });
    if (res?.approval_url) {
      window.location.href = res?.approval_url;
    }
  };

  if (isLoading) {
    return (
      <div style={{ height: 400 }}>
        <Loading />
      </div>
    );
  }

  return (
    <S.Wrapper>
      <TopMenu>
        <ul className="nav">
          <li>
            <Button basic as={Link} to={`/account`}>
              <T id="account.title" />
            </Button>
          </li>
          <li>
            <Icon name="pathTo" />
          </li>
        </ul>
      </TopMenu>

      <div className="main-section">
        <div className="header">
          <h1>
            <T id="upgrade.title" />
          </h1>
          <p>
            <T id="upgrade.description" />
          </p>
        </div>
        <S.SectionWrapper>
          <h4>
            <T id="upgrade.current_plan" />
          </h4>
          <SubscriptionCard
            type={currentAccount.subscription_type}
            expire={currentAccount.expiration_date}
          />
        </S.SectionWrapper>
        {upgradePlans.length === 0 ? (
          <S.SectionWrapper>
            <h4>
              <T id="upgrade.advanced_plan" />
            </h4>
            <p>
              <T id="upgrade.contact_support" />
              <a href="mailto:support@lumopages.com">
                support@lumopages.com
              </a>{" "}
              <T id="upgrade.help_emoji" />
            </p>
          </S.SectionWrapper>
        ) : (
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, values }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <S.SectionWrapper>
                    <h4>
                      <T id="upgrade.options" />
                    </h4>
                    <Field name="plan" validate={required}>
                      {({ meta, input }) => {
                        return (
                          <FormField>
                            <input
                              type="hidden"
                              name={input.name}
                              value={input.value}
                              onChange={input.onChange}
                            />
                            <PlanOptionsWrapper>
                              {upgradePlans.map((plan) => (
                                <Button
                                  className={
                                    plan.id === values.plan ? "ls-selected" : ""
                                  }
                                  type="button"
                                  onClick={() => input.onChange(plan.id)}
                                >
                                  <h3>
                                    <T id={plan.name} />
                                  </h3>
                                  <h5>
                                    <T id={plan.type} />
                                  </h5>
                                  <div className="price">
                                    {plan.price ? (
                                      <>
                                        <span className="p-m">$</span>
                                        <span className="p-l">
                                          {plan.price}
                                        </span>
                                        <span className="p-s">/mo</span>
                                      </>
                                    ) : (
                                      <span className="p-m">
                                        <T id="upgrade.free" />
                                      </span>
                                    )}
                                  </div>
                                </Button>
                              ))}
                            </PlanOptionsWrapper>
                            {meta.touched &&
                              (meta.error || meta.submitError) && (
                                <p className="ls-error">
                                  {meta.error || meta.submitError}
                                </p>
                              )}
                          </FormField>
                        );
                      }}
                    </Field>
                  </S.SectionWrapper>
                  <S.SectionWrapper>
                    <h4>
                      <T id="upgrade.payment" />
                    </h4>
                    <PaymentSummary planId={values.plan} />
                  </S.SectionWrapper>
                </form>
              );
            }}
          />
        )}
      </div>
    </S.Wrapper>
  );
});

const PaymentSummary = ({ planId }) => {
  const { t } = useTranslation();
  const { accountStore } = useStore();
  const { currentAccount } = accountStore;

  if (!planId) {
    return (
      <p>
        <T id="upgrade.no_plan_selected" />
      </p>
    );
  }
  const plan = plans[planId];
  const now = new Date();
  const expired =
    plan.type === "plans.annualy" ? addOneYear(now) : addOneMonth(now);
  const start = formatDate(now, "date");
  const end = formatDate(expired, "date");
  const currentPlanPeriod = isDateWithinMonth(currentAccount.expiration_date)
    ? "plans.monthly"
    : "plans.annualy";
  const currentPlan =
    plans[`${currentAccount.subscription_type}${currentPlanPeriod}`];

  const refund = currentPlan
    ? calculateRefund(currentAccount.expiration_date, currentPlan.price)
    : 0;
  const refundEnd = formatDate(currentAccount.expiration_date, "date");

  return (
    <>
      <PaymentSummaryWrapper>
        <li>
          <p>
            <T
              id="upgrade.summary"
              values={{ plan: t(plan.name), start, end }}
              components={{ 1: <span /> }}
            />
          </p>
          <p>{plan.price * (plan.type === "Annualy" ? 12 : 1)}$</p>
        </li>
        {refund > 0 && (
          <li>
            <p>
              <T
                id="upgrade.refund"
                values={{ plan: t(currentPlan.name), start, refundEnd }}
              />
            </p>
            <p>-{refund}$</p>
          </li>
        )}

        <li className="ls-sum">
          <p>
            <T id="upgrade.total_cost" />
          </p>
          <p>{plan.price * (plan.type === "Annualy" ? 12 : 1) - refund}$</p>
        </li>
      </PaymentSummaryWrapper>
      <Button primary>
        <T id="upgrade.subscribe_paypal" />
      </Button>
    </>
  );
};

const isDateWithinMonth = (dateStr) => {
  let inputDate = new Date(dateStr);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const oneMoreMonth = new Date(today);
  oneMoreMonth.setMonth(today.getMonth() + 1);

  return inputDate < oneMoreMonth;
};

function calculateRefund(expirationDateStr, monthlyPrice) {
  const expirationDate = new Date(expirationDateStr);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const msInADay = 24 * 60 * 60 * 1000;
  const daysLeft = Math.ceil((expirationDate - today) / msInADay);

  if (daysLeft <= 0) {
    return { daysLeft: 0, refundAmount: 0 };
  }

  const dailyRate = monthlyPrice / 30;
  const refundAmount = dailyRate * daysLeft;

  return refundAmount.toFixed(0);
}
