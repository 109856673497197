import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useStore } from "structure";
import { Icon } from "assets/icons/Icon";
import { Content } from "components/Website/Content";
import { Loading } from "components/shared/Loading";

import { T } from "util/Translation";

import { AsText } from "ui/common-styles";
import * as S from "./styles";
import { Button } from "semantic-ui-react";
import { ResponsePreview } from "./ResponsePreview";

export const ProjectPreview = observer(() => {
  const { projectsStore } = useStore();
  const [replyContent, setReplyContent] = useState();
  const [viewResponse, setViewResponse] = useState();
  let { projectId } = useParams();
  const project = projectsStore.findProjectById(projectId);
  const data = {
    ...project,
    settings: project?.public_settings,
  };

  useEffect(() => {
    if (!project) {
      projectsStore.fetchProjects();
    }
    // eslint-disable-next-line
  }, []);

  if (!project || projectsStore.isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <S.PreviewHeader>
        <AsText as={Link} to={`/project/${projectId}`}>
          <Icon name="left" height={20} />
          <T id="project.back_to_management" />
        </AsText>

        <p>
          <T id="project.preview_title" />
        </p>
        <Button
          className="ls-bright"
          primary
          disabled={!replyContent}
          onClick={() => setViewResponse(!viewResponse)}
        >
          {viewResponse ? "Start again" : "View response"}
        </Button>
      </S.PreviewHeader>
      <div style={{ marginTop: 80 }}>
        {viewResponse ? (
          <ResponsePreview data={replyContent} />
        ) : (
          <Content data={data} setReplyContent={setReplyContent} />
        )}
      </div>
    </div>
  );
});
