import { DragOverlay, useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { Icon } from "assets/icons/Icon";
import { useTranslation } from "react-i18next";
import { T } from "util/Translation";

export const Draggable = ({ tool }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useDraggable({
    id: `tool-${tool.id}`,
  });
  const { t } = useTranslation();
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <>
      <button
        key={tool.id}
        ref={setNodeRef}
        style={style}
        className="tool"
        alt={tool.desc}
        {...listeners}
        {...attributes}
      >
        <Icon name={tool.icon} size="big" />
        <p>
          <T id={tool.name} />
        </p>
      </button>

      {isDragging && (
        <DragOverlay>
          <button className="tool tool-active" alt={t(tool.desc)}>
            <Icon name={tool.icon} size="big" />
            <p>
              <T id={tool.name} />
            </p>
          </button>
        </DragOverlay>
      )}
    </>
  );
};
