import { CreateProjectModal } from "components/shared/CreateProjectModal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Button, Placeholder, PlaceholderLine } from "semantic-ui-react";
import { useStore } from "structure";
import { T } from "util/Translation";
import { ProjectPreview } from "../Projects/ProjectPreview";
import { toast } from "react-toastify";
import { NewProject } from "../Projects/NewProject";
import { AsText } from "ui/common-styles";
import { Icon } from "assets/icons/Icon";
import { Confirm } from "ui/Confirm";

export const ProjectSection = () => {
  const { t } = useTranslation();
  const { projectsStore } = useStore();
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const { projects } = projectsStore;
  const [deleteConfirmId, setDeleteConfirmId] = useState(false);
  const [publishConfirmId, setPublishConfirmId] = useState(false);
  const [draftConfirmId, setDraftConfirmId] = useState(false);
  const history = useHistory();

  useEffect(() => {
    projectsStore.fetchProjects().then(() => setIsLoading(false));
    return () => setIsLoading(true);
    // eslint-disable-next-line
  }, []);

  const handleDeleteProject = async (projectId) => {
    await projectsStore.delete(projectId);
    toast.success(t("project.deleted_successfully"));
    history.push(`/projects`);
  };

  const handlePublicProject = async (projectId, isPublic) => {
    await projectsStore
      .update(projectId, { isPublic })
      .then(() => projectsStore.fetchProjects());
    toast.success(isPublic ? t("project.published") : t("project.unpublished"));
  };

  if (isLoading) {
    return (
      <Placeholder>
        <PlaceholderLine />
      </Placeholder>
    );
  }

  if (projects.length === 6) {
    return (
      <>
        <h5>
          <T id="home.projects.empty_title" />
        </h5>
        <p>
          <T id="home.projects.empty_text" />
        </p>
        <Button primary className="ls-bright" onClick={() => setOpen(true)}>
          <T id="home.projects.empty_cta" />
        </Button>
        <CreateProjectModal open={open} setOpen={setOpen} />
      </>
    );
  }

  return (
    <>
      <div className="ls-header">
        <h4>
          <T id="home.projects.title" />
        </h4>
      </div>
      <div className="ls-projects">
        <NewProject />
        {projects.slice(0, 3).map((project) => (
          <ProjectPreview
            key={project.id}
            data={project}
            actions={{
              handleDelete: setDeleteConfirmId,
              handlePublish: setPublishConfirmId,
              handleDraft: setDraftConfirmId,
            }}
          />
        ))}
      </div>
      <div className="ls-more-projects">
        <AsText as={Link} to="/projects">
          <T id="home.projects.all_projects" />
          <Icon name="right" />
        </AsText>
      </div>

      <CreateProjectModal open={open} setOpen={setOpen} />

      <Confirm
        title={t("project.delete_title")}
        text={t("project.delete_confirmation")}
        confirmButton={t("project.confirm_delete")}
        color="red"
        open={!!deleteConfirmId}
        onCancel={() => setDeleteConfirmId(null)}
        onConfirm={() => handleDeleteProject(deleteConfirmId)}
      />

      <Confirm
        title={t("project.publish_title")}
        text={t("project.publish_confirmation")}
        confirmButton={t("project.confirm_publish")}
        open={!!publishConfirmId}
        onCancel={() => setPublishConfirmId(null)}
        onConfirm={() => handlePublicProject(publishConfirmId, true)}
      />

      <Confirm
        title={t("project.unpublish_title")}
        text={t("project.unpublish_confirmation")}
        confirmButton={t("project.confirm_unpublish")}
        open={!!draftConfirmId}
        onCancel={() => setDraftConfirmId(null)}
        onConfirm={() => handlePublicProject(draftConfirmId, false)}
      />
    </>
  );
};
