import { TextSettings } from "./Elements/TextSettings";
import { TextAreaSettings } from "./Elements/TextAreaSettings";
import { RadioGroupSettings } from "./Elements/RadioGroupSettings";
import { DropdownSettings } from "./Elements/DropdownSettings";
import { GallerySettings } from "./Elements/GallerySettings";
import { VideoSettings } from "./Elements/VideoSettings";
import { CheckboxSettings } from "./Elements/CheckboxSettings";
import { DateSettings } from "./Elements/DateSettings";
import { ImageSettings } from "./Elements/ImageSettings";

import { T } from "util/Translation";

const allowed = [2, 3, 4, 5, 6, 7, 10, 11, 12];

export const ContentElementSection = ({ blockId, type }) => {
  if (!allowed.some((item) => `tool-${item}` === type)) {
    return null;
  }

  return (
    <div className="ls-section">
      <h6>
        <T id="block_settings.content_section_title" />
      </h6>
      {type === "tool-2" && <TextSettings blockId={blockId} />}

      {type === "tool-3" && <TextAreaSettings blockId={blockId} />}

      {type === "tool-4" && <RadioGroupSettings blockId={blockId} />}

      {type === "tool-5" && <DropdownSettings blockId={blockId} />}

      {type === "tool-6" && <CheckboxSettings blockId={blockId} />}

      {type === "tool-7" && <DateSettings blockId={blockId} />}

      {type === "tool-10" && <ImageSettings blockId={blockId} />}

      {type === "tool-11" && <GallerySettings blockId={blockId} />}

      {type === "tool-12" && <VideoSettings blockId={blockId} />}
    </div>
  );
};
