import { useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useHistory, useParams, Link } from "react-router-dom";

import { useDndMonitor } from "@dnd-kit/core";
import { useStore } from "structure";
import { Form } from "react-final-form";
import { Button } from "semantic-ui-react";
import arrayMutators from "final-form-arrays";

import { Icon } from "assets/icons/Icon";
import { Draggable } from "components/shared/creationComponents/DNDComponents";
import { NoData } from "components/shared/NoData";
import { Loading } from "components/shared/Loading";
import { Confirm } from "ui/Confirm";
import { TopMenu } from "ui/common-styles";
import { DuplicateFormModal } from "./DuplicateFormModal";
import { BlockSettings } from "./BlockSettings";
import { FormTitleSection } from "./FormTitleSection";
import { MainBord } from "./MainBord";

import { T } from "util/Translation";
import { useTranslation } from "react-i18next";

import { BUILD_PARTS, DISPLAY_TOOLS, FORM_TOOLS } from "util/consts";
import { makeid } from "util/helpers";

import * as S from "./styles";
import { toast } from "react-toastify";

const getContentFromPart = (project, partId) => {
  if (!project || !project.settings) return [];
  const settings =
    typeof project.settings === "string"
      ? JSON.parse(project.settings)
      : project.settings;
  return settings?.[partId]?.content || [];
};

export const Interface = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { projectId, partType, partId } = useParams();
  const { projectsStore, formEditorStore } = useStore();
  const [project, setProject] = useState(null);
  const [part, setPart] = useState(null);
  const [isNotFind, setIsNotFind] = useState(false);
  const [isEditFormName, setIsEditFormName] = useState(false);
  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
  const freeBlockNavigation = useRef(false);
  const [nextLocation, setNextLocation] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const blocksOrderPartValues = useMemo(() => getFormValues(part), [part]);
  let formRef = null;

  useEffect(() => {
    projectsStore
      .getById(projectId)
      .then((project) => {
        if (project) {
          setProject(project);
          if (partType === "form") {
            const form =
              project?.settings?.order?.find(
                (element) => element.form.id === partId
              )?.form || null;

            if (form) {
              setPart(form);
            } else {
              throw new Error();
            }
          }
          if (partType === "part" && BUILD_PARTS[partId]) {
            setPart(getContentFromPart(project, partId));
          }
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        setIsNotFind(true);
      });
    // eslint-disable-next-line
  }, [partId]);

  useDndMonitor({
    onDragStart({ active }) {
      if (active?.id) formEditorStore.setActiveElement(active.id);
    },
    onDragEnd(event) {
      const { active, over } = event;
      if (!over?.id || !active?.id) return;
      if (active?.id?.includes("tool-")) {
        const typeName = active?.id;
        if (typeName) {
          const newBlockId = makeid();
          addBlock(newBlockId, typeName, over?.id);
        }
      } else if (active?.id?.includes("block-")) {
        formEditorStore.moveBlocks(active.id, over.id);
      }
      formEditorStore.setActiveElement(null);
    },
  });

  const addBlock = (newBlockId, typeName, afterBlock = null) => {
    formEditorStore.add(
      {
        id: newBlockId,
        type: typeName,
      },
      afterBlock
    );

    if (afterBlock) {
      formEditorStore.setBlockEditId(newBlockId);
    }
  };

  const createEditedFormObj = () => {
    const { blocksOrder, blocks } = formEditorStore;
    const { values } = formRef?.getState() || {};

    return {
      ...part,
      name: formEditorStore.formName,
      fields: blocksOrder.map((blockId) => {
        const type = blocks.get(blockId)?.type;
        return {
          [type]: values?.[blockId]?.[type] || {},
        };
      }),
    };
  };

  const onSubmitForm = async () => {
    const newFormObj = createEditedFormObj();
    const newForm = await projectsStore.saveForm(project, partId, newFormObj);
    if (newForm) {
      formEditorStore.updateSavedBlocksOrder();
      toast.success(
        t("interface.saved_successfully", {
          part: t(BUILD_PARTS[partId]?.name || "build_parts.form_name"),
        })
      );
    }
  };

  const onSubmitPart = async () => {
    if (!BUILD_PARTS[partId].key) return;
    const newContentObj = createEditedFormObj();
    const update = {
      [BUILD_PARTS[partId].key]: {
        isActive:
          project.settings?.[BUILD_PARTS[partId].key]?.isActive &&
          newContentObj?.length > 0
            ? true
            : false,
        content: newContentObj,
      },
    };
    const res = await projectsStore.saveBuildContent(project, update);
    const newPart = getContentFromPart(res, partId);
    if (res) {
      formEditorStore.updateSavedBlocksOrder();
      setPart(newPart); /* maybe remove ?? */
      toast.success(
        t("interface.saved_successfully", {
          part: t(BUILD_PARTS[partId]?.name || "build_parts.form_name"),
        })
      );
    }
  };

  const handleDeleteForm = async () => {
    await projectsStore.deleteForm(projectId, partId).then((success) => {
      if (success) {
        toast.success(
          t("interface.deleted_successfully", {
            part: t(BUILD_PARTS[partId]?.name || "build_parts.form_name"),
          })
        );
        history.push(`/project/${projectId}`);
      }
    });
  };

  const handleClearPart = async () => {
    if (!BUILD_PARTS[partId].key) return;
    const res = await projectsStore.saveBuildContent(project, {
      [BUILD_PARTS[partId].key]: {
        isActive: false,
        content: [],
      },
    });
    if (res) {
      toast.success(
        t("interface.deleted_successfully", {
          part: t(BUILD_PARTS[partId]?.name || "build_parts.form_name"),
        })
      );
      history.push(`/project/${projectId}`);
    }
  };

  const handleSaveForm = async () => {
    return formRef?.submit();
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      formRef?.submit();
    }
  };

  const changeFormName = async ({ formName }) => {
    const newForm = await projectsStore.saveForm(project, partId, {
      ...part,
      name: formName,
    });
    if (newForm) {
      formEditorStore.setFormName(formName);
      setIsEditFormName(false);
    }
  };

  const handleBlockedNavigation = (nextLocation) => {
    setShowSaveChangesModal(true);
    setNextLocation(nextLocation);
    return false;
  };

  const checkForUnsavedChanges = () => {
    const { submitSucceeded, dirtySinceLastSubmit, dirty } =
      formRef?.getState() || {};
    return (
      (submitSucceeded ? dirtySinceLastSubmit : dirty) ||
      formEditorStore.hasUnsavedChanges
    );
  };

  useEffect(() => {
    if (partType === "form" && part) {
      formEditorStore.setFormName(part?.name || t("interface.untitled"));
    }

    if (blocksOrderPartValues) {
      blocksOrderPartValues.order.forEach((id) => {
        if (!id) return;
        const typeName = Object.keys(blocksOrderPartValues.blocks[id])?.[0];
        addBlock(id, typeName);
      });
    }
    formEditorStore.updateSavedBlocksOrder();
    formEditorStore.setIsLoading(false);

    const handleBeforeUnload = (e) => {
      const hasChanges = checkForUnsavedChanges();
      if (hasChanges) {
        const confirmationMessage = t("interface.unsaved_alert");
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    const unblock = history.block((nextLocation) => {
      const hasChanges = checkForUnsavedChanges();
      if (hasChanges && !freeBlockNavigation.current)
        return handleBlockedNavigation(nextLocation.pathname);
      return true;
    });
    return () => {
      unblock();
      window.removeEventListener("beforeunload", handleBeforeUnload);
      formEditorStore.reset();
    };
    // eslint-disable-next-line
  }, [blocksOrderPartValues]);

  const handleStay = () => {
    setNextLocation(null);
    setShowSaveChangesModal(false);
  };

  const handleOut = () => {
    freeBlockNavigation.current = true;
    history.push(nextLocation);
  };

  const handleOutWithSaving = async () => {
    await formRef?.submit();
    handleOut();
  };

  if (isNotFind) {
    return (
      <NoData
        title={t("interface.missing_page.title")}
        text={t("interface.missing_page.text")}
      />
    );
  }

  if (!project || !part || formEditorStore.isLoading) {
    return <Loading />;
  }

  return (
    <S.PanelWrapper>
      <TopMenu>
        <ul className="nav">
          <li>
            <Button basic as={Link} to={`/projects`}>
              <T id="interface.projects" />
            </Button>
          </li>
          <li>
            <Icon name="pathTo" />
          </li>
          <li>
            <Button basic as={Link} to={`/project/${projectId}`}>
              <T
                id="interface.project_name"
                values={{ name: project?.name || "Untitled" }}
              />
            </Button>
          </li>
        </ul>

        <ul>
          <li>
            <Button
              className="ls-bright ls-icon"
              color="grey"
              onClick={handleSaveForm}
              title={t("interface.save")}
            >
              <Icon name="save" />
            </Button>
          </li>
          {partType === "form" && (
            <>
              <li>
                <Button
                  className="ls-bright ls-icon"
                  color="grey"
                  onClick={() => setOpenDuplicateModal(true)}
                >
                  <Icon name="copy" />
                </Button>
              </li>
            </>
          )}

          <li>
            <Button
              className="ls-bright ls-icon"
              color="grey"
              onClick={() => setIsDeleteModalOpen(true)}
            >
              <Icon name="delete" />
            </Button>
          </li>
        </ul>
      </TopMenu>

      <S.Container onKeyDown={handleKeyDown}>
        <S.EditorWrapper>
          <S.ToolsWrapper>
            <h4>
              <T id="interface.build_tools" />
            </h4>
            <div className="ls-tool-section">
              <h6>
                <T id="interface.display_blocks" />
              </h6>
              <div>
                {DISPLAY_TOOLS.map((tool) => (
                  <Draggable key={`tool-${tool.id}`} tool={tool} />
                ))}
              </div>
            </div>
            {partType === "form" && (
              <div className="ls-tool-section">
                <h6>
                  <T id="interface.input_blocks" />
                </h6>
                <div>
                  {FORM_TOOLS.map((tool) => (
                    <Draggable key={`tool-${tool.id}`} tool={tool} />
                  ))}
                </div>
              </div>
            )}
          </S.ToolsWrapper>
          <Form
            onSubmit={partType === "form" ? onSubmitForm : onSubmitPart}
            mutators={{
              ...arrayMutators,
            }}
            initialValues={blocksOrderPartValues.blocks}
            render={({ handleSubmit, form }) => {
              formRef = form;
              return (
                <S.MainFormEditing>
                  <div className="ls-main-editor">
                    {partType === "form" ? (
                      <FormTitleSection
                        isEditFormName={isEditFormName}
                        changeFormName={changeFormName}
                        setIsEditFormName={setIsEditFormName}
                      />
                    ) : (
                      <div className="ls-form-title">
                        <h1>
                          <T
                            id={
                              BUILD_PARTS[partId]?.name ||
                              "build_parts.form_name"
                            }
                          />
                        </h1>
                      </div>
                    )}

                    <S.PageWrapper onSubmit={handleSubmit}>
                      <MainBord />
                    </S.PageWrapper>
                  </div>
                  <BlockSettings />
                </S.MainFormEditing>
              );
            }}
          />
        </S.EditorWrapper>
      </S.Container>

      {partType === "form" && (
        <Confirm
          title={t("interface.delete_project_title")}
          text={t("interface.delete_project_text")}
          confirmButton={t("interface.confirm_delete")}
          color="red"
          open={isDeleteModalOpen}
          onCancel={() => setIsDeleteModalOpen(false)}
          onConfirm={handleDeleteForm}
        />
      )}

      {partType === "part" && (
        <Confirm
          title={t("interface.clear_content_title", {
            part: t(BUILD_PARTS[partId]?.name || "build_parts.form_name"),
          })}
          text={t("interface.clear_content_text", {
            part: t(BUILD_PARTS[partId]?.name || "build_parts.form_name"),
          })}
          confirmButton={t("interface.confirm_clear")}
          color="red"
          open={isDeleteModalOpen}
          onCancel={() => setIsDeleteModalOpen(false)}
          onConfirm={handleClearPart}
        />
      )}

      <Confirm
        title={t("interface.unsaved_changes_title")}
        text={t("interface.unsaved_changes_text")}
        confirmButton={t("interface.save_changes")}
        cancelButton={t("interface.discard_changes")}
        open={showSaveChangesModal}
        onCancel={handleOut}
        onConfirm={handleOutWithSaving}
        onClose={handleStay}
      />

      {partType === "form" && (
        <DuplicateFormModal
          open={openDuplicateModal}
          setOpen={setOpenDuplicateModal}
          projectId={projectId}
          sourceForm={part}
        />
      )}
    </S.PanelWrapper>
  );
});

function getFormValues(form) {
  const blocks = {};
  const order = [];

  if (Array.isArray(form?.fields)) {
    form.fields.forEach((field) => {
      const uniqueId = makeid();
      const [toolKey, toolValue] = Object.entries(field)[0];
      order.push(uniqueId);
      blocks[uniqueId] = { [toolKey]: toolValue };
    });
  }

  return { blocks, order };
}
