import { observer } from "mobx-react-lite";
import { AsText } from "ui/common-styles";
import * as S from "./styles.js";
import { useStore } from "structure/index.js";
import { T } from "util/Translation";

export const PanelTemplate = observer(({ pages }) => {
  const { projectsStore } = useStore();
  const { panelTemplateIndex } = projectsStore;

  return (
    <S.PaneWrapper>
      <ul className="ls-option-list">
        {pages.map((page, idx) => (
          <li
            className={idx === panelTemplateIndex ? "ls-selected" : ""}
            key={idx}
          >
            <AsText onClick={() => projectsStore.setPanelTemplateIndex(idx)}>
              <span>{page.name}</span>{" "}
              {typeof page.value === "string" ? (
                <span>{page.value}</span>
              ) : (
                <div>{page.value}</div>
              )}
            </AsText>
          </li>
        ))}
      </ul>
      <div className="ls-content">
        {pages[panelTemplateIndex]?.content || null}
      </div>
    </S.PaneWrapper>
  );
});

export const ActiveTag = ({ isActive }) => {
  return (
    <span className={`ls-tag ${isActive ? "ls-active" : ""}`}>
      <T id={isActive ? "status.active" : "status.disabled"} />
    </span>
  );
};
