import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "structure";
import { toast } from "react-toastify";
import { formatDate, formatSubmissionDateTime } from "util/helpers";
import { useTranslation } from "react-i18next";

import { Button, Pagination } from "semantic-ui-react";
import { Form } from "react-final-form";
import { Loading } from "components/shared/Loading";
import { FieldText } from "ui/FieldText";
import { FieldDropdown } from "ui/FieldDropdown";
import { Icon } from "assets/icons/Icon";
import { BasicButton } from "ui/common-styles";
import { Confirm } from "ui/Confirm";
import { T } from "util/Translation";

import * as S from "./styles";

export const Submissions = observer(() => {
  const { t } = useTranslation();
  const { repliesStore, projectsStore } = useStore();
  const [isLoading, setIsLoading] = useState(true);
  const [confirmReplyDeleteId, setConfirmReplyDeleteId] = useState(null);
  const [indexPage, setIndexPage] = useState(1);
  const [response, setResponse] = useState(null);
  const { replies, paginationMeta } = repliesStore;
  const [allProjects, setAllProjects] = useState(null);

  useEffect(() => {
    repliesStore.fetchReplies().then(() => setIsLoading(false));
    projectsStore.fetchAllProjects().then((data) =>
      setAllProjects(
        data.map((item) => ({
          key: item.id,
          value: item.id,
          text: item.name,
        }))
      )
    );
    return () => setIsLoading(true);
    // eslint-disable-next-line
  }, []);

  const handleDeleteReply = async () => {
    await repliesStore.delete(confirmReplyDeleteId);
    toast.success(t("submissions.reply_deleted"));
  };

  const showResponse = (item) => {
    setResponse(item);
  };

  useEffect(() => {
    if (!replies) return;
    const data = replies[0];
    if (!data) {
      showResponse(null);
      return;
    }
    const response = JSON.parse(data.response);
    showResponse({
      id: data.id,
      data: response,
      meta: {
        projectId: data.project_id,
        projectName: data.project_name,
        country: data.sender_country,
        time: formatDate(data.submission_time),
      },
    });
    // eslint-disable-next-line
  }, [replies]);

  const handleSearch = (data) => {
    setIsLoading(true);
    repliesStore.fetchReplies(data).then(() => setIsLoading(false));
  };

  return (
    <S.Wrapper>
      <div className="main-section">
        <div className="header">
          <h1>
            <T id="submissions.title" />
          </h1>
          <p>
            <T id="submissions.intro" />
          </p>
        </div>
      </div>

      <div className="ls-filters">
        <Form
          onSubmit={handleSearch}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <FieldText
                label={t("submissions.search_label")}
                finalFormProps={{ name: "search" }}
                semanticProps={{
                  placeholder: t("submissions.search_placeholder"),
                  disabled: submitting,
                }}
              />
              <FieldDropdown
                label={t("submissions.select_project")}
                finalFormProps={{ name: "project_ids" }}
                semanticProps={{
                  placeholder: t("submissions.select_project_placeholder"),
                  disabled: submitting || !allProjects,
                  loading: !allProjects,
                  options: allProjects || [],
                  multiple: true,
                  defaultValue: [],
                }}
              />
              <div className="ls-actions">
                <Button basic type="button" onClick={() => handleSearch({})}>
                  {t("submissions.reset")}
                </Button>
                <Button primary type="submit">
                  {t("submissions.search")}
                </Button>
              </div>
            </form>
          )}
        />
      </div>

      <div className="ls-content">
        {isLoading ? (
          <div style={{ height: 400 }}>
            <Loading />
          </div>
        ) : (
          <S.PanelWrapper>
            <div className="ls-results">
              {replies.length === 0 ? (
                <div className="ls-empty">
                  <h4>
                    <T id="submissions.no_results" />
                  </h4>
                </div>
              ) : (
                <ul>
                  {replies.map((reply) => (
                    <SubmissionItem
                      key={reply.id}
                      data={reply}
                      showResponse={showResponse}
                      isActive={response?.id === reply.id}
                    />
                  ))}
                </ul>
              )}

              {paginationMeta.last_page && (
                <Pagination
                  activePage={indexPage}
                  totalPages={paginationMeta.last_page}
                  boundaryRange={0}
                  siblingRange={1}
                  ellipsisItem={null}
                  firstItem={null}
                  lastItem={null}
                  prevItem={{ content: <Icon name="left" />, icon: true }}
                  nextItem={{ content: <Icon name="right" />, icon: true }}
                  onPageChange={(e, data) => {
                    setIndexPage(data.activePage);
                    repliesStore.fetchReplies({ page: data.activePage });
                  }}
                />
              )}
            </div>

            <S.SidebarContent>
              {!response ? (
                <p>
                  <T id="submissions.no_data" />
                </p>
              ) : (
                <ul className="ls-form-response" key={response.id}>
                  <li className="project-reply-title">
                    <h3>
                      <T id="submissions.project_name" />{" "}
                      {response.meta.projectName}
                    </h3>
                    <ul className="project-reply-meta">
                      <li>
                        <T id="submissions.project_id" />{" "}
                        <span>{response.meta.projectId}</span>
                      </li>
                      <li>
                        <T id="submissions.submitted_at" />{" "}
                        <span>{response.meta.time}</span>
                      </li>
                      <li>
                        <T id="submissions.submitted_from" />{" "}
                        <span>{response.meta.country}</span>
                      </li>
                    </ul>
                  </li>
                  {response.data.map((form, idx) => (
                    <li key={idx}>
                      <h6>{form.name}</h6>
                      <ul className="project-reply-content">
                        {form.fields.map((field, s_idx) => (
                          <li key={s_idx}>
                            <span>{field.field}</span>
                            <span>{field.value}</span>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              )}
            </S.SidebarContent>
          </S.PanelWrapper>
        )}
      </div>

      <Confirm
        title={t("submissions.delete_confirm_title")}
        text={t("submissions.delete_confirm_text")}
        confirmButton={t("submissions.delete_confirm_button")}
        color="red"
        open={!!confirmReplyDeleteId}
        onCancel={() => setConfirmReplyDeleteId(null)}
        onConfirm={handleDeleteReply}
      />
    </S.Wrapper>
  );
});

const SubmissionItem = ({ data, showResponse, isActive }) => {
  const response = JSON.parse(data.response);
  const replyName = response[0].fields.find((field) =>
    ["name", "first name", "full name"].includes(field.field.toLowerCase())
  );
  const submissionTime = formatSubmissionDateTime(data.submission_time);

  return (
    <S.SubmissionItemWrapper className={isActive ? "ls-active" : ""}>
      <BasicButton
        onClick={() =>
          showResponse({
            id: data.id,
            data: response,
            meta: {
              projectId: data.project_id,
              projectName: data.project_name,
              country: data.sender_country,
              time: formatDate(data.submission_time),
            },
          })
        }
      >
        <h5>
          {replyName?.value ? (
            replyName.value
          ) : (
            <T id="submissions.new_reply" />
          )}
        </h5>
        <div className="ls-details">
          <span>{data.project_name}</span>
          <span>{submissionTime}</span>
        </div>
      </BasicButton>
    </S.SubmissionItemWrapper>
  );
};
