import { observer } from "mobx-react-lite";
import { useStore } from "structure";
import * as S from "./styles";
import { Form } from "react-final-form";
import { FieldFile } from "ui/FieldFile";
import {
  fileTypes,
  maxFileSize,
  multiValidators,
  required,
} from "util/validators";
import { toast } from "react-toastify";
import { UserAvatarView } from "components/shared/UserAvatarView";
import { ButtonWithIcon } from "ui/common-styles";
import { Icon } from "assets/icons/Icon";
import { useState } from "react";
import { Confirm } from "ui/Confirm";
import { useTranslation } from "react-i18next";
import { T } from "util/Translation";

export const Avatar = observer(() => {
  const { t } = useTranslation();
  const { accountStore } = useStore();
  const { currentAccount } = accountStore;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState();

  const submitFile = (selectedFile) => {
    if (selectedFile) {
      accountStore
        .updateAvatar(selectedFile)
        .then(() => {
          toast.success(t("avatar.update_success"));
        })
        .catch((error) => {
          toast.success(t("avatar.update_error"));
          console.error("Error uploading file:", error);
        });
    }
  };

  return (
    <>
      <Form
        onSubmit={() => null}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <S.AvatarSectionWrapper>
                <UserAvatarView />
                <h4>{currentAccount?.username}</h4>
                <div className="ls-actions">
                  <FieldFile
                    icon="edit"
                    btnText={t("avatar.replace_button")}
                    btnProps={{
                      className: "ls-bright",
                      primary: true,
                      basic: true,
                      size: "small",
                    }}
                    customOnChange={(file) => submitFile(file)}
                    finalFormProps={{
                      name: "file",
                      validate: multiValidators([
                        required,
                        maxFileSize(2),
                        fileTypes([
                          "image/jpeg",
                          "image/png",
                          "image/jpg",
                          "image/gif",
                        ]),
                      ]),
                    }}
                  />
                  <ButtonWithIcon
                    basic
                    className="ls-bright"
                    color="red"
                    size="small"
                    type="button"
                    onClick={() => setIsDeleteModalOpen(true)}
                  >
                    <Icon name="delete" height={18} />
                    <T id="avatar.remove_button" />
                  </ButtonWithIcon>
                </div>
              </S.AvatarSectionWrapper>
            </form>
          );
        }}
      />
      <Confirm
        title={t("avatar.confirm_remove_title")}
        text={t("avatar.confirm_remove_text")}
        confirmButton={t("avatar.confirm_remove_button")}
        color="red"
        open={isDeleteModalOpen}
        onCancel={() => setIsDeleteModalOpen(false)}
        onConfirm={() => accountStore.deleteAvatar()}
      />
    </>
  );
});
