import { observer } from "mobx-react-lite";
import { GeneralEdit } from "ui/common-styles";
import { formatDate } from "util/helpers";

import { T } from "util/Translation";

export const Info = observer(({ project }) => {
  const created = formatDate(project.created_at, "datetime");
  const updated = formatDate(project.updated_at, "datetime");

  return (
    <GeneralEdit>
      <h4>
        <T id="info.title" />
      </h4>
      <p>
        <T id="info.description" />
      </p>

      <div className="ls-main-block">
        <ul>
          <li className="ls-one-line">
            <span>
              <T id="info.created_at" />
            </span>
            <span>{created}</span>
          </li>
          <li className="ls-one-line">
            <span>
              <T id="info.updated_at" />
            </span>
            <span>{updated}</span>
          </li>
        </ul>
      </div>
    </GeneralEdit>
  );
});
