import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";

import { useStore } from "structure";
import { Form } from "react-final-form";
import { Button } from "semantic-ui-react";
import { MediaItem } from "components/shared/MediaGalleryModal/MediaItem";
import { toast } from "react-toastify";

import { T } from "util/Translation";
import { useTranslation } from "react-i18next";

import { API_URL } from "util/consts";

import * as S from "./styles";

export const MediaGallery = observer(({ onSelect, selected, onCancel }) => {
  const { t } = useTranslation();
  const { mediaStore } = useStore();
  const inputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(selected || null);

  const handleImageClick = (item) => {
    setSelectedImage(item);
  };

  useEffect(() => {
    mediaStore.fetchMedia();
    // eslint-disable-next-line
  }, []);

  const onSubmit = () => {
    if (selectedFile) {
      mediaStore
        .addImage(selectedFile)
        .then(() => {
          toast.success(t("media_gallery.upload_success"));
          inputRef.current.value = null;
          setSelectedFile(null);
        })
        .catch((error) => {
          toast.error(t("media_gallery.upload_error"));
          console.error("Error uploading file:", error);
        });
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const gallery = mediaStore?.media?.images || [];

  return (
    <S.GalleryWrapper>
      <div className="gallery-upload">
        {!!onSelect ? (
          <div style={{ float: "right", paddingBottom: 16 }}>
            <Button
              basic
              content={t("media_gallery.cancel")}
              onClick={onCancel}
              style={{ marginRight: 16 }}
            />
            <Button
              onClick={() => onSelect(`${API_URL}${selectedImage}`)}
              primary
              content={t("media_gallery.select")}
              disabled={!selectedImage}
            />
          </div>
        ) : (
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <input
                    ref={inputRef}
                    type="file"
                    name="image"
                    onChange={handleFileChange}
                  />
                  <Button
                    type="submit"
                    primary
                    content={t("media_gallery.upload")}
                    disabled={!selectedFile}
                  />
                </form>
              );
            }}
          />
        )}
      </div>

      <div className="gallery-grid">
        {gallery.map((item) => (
          <MediaItem
            key={item}
            item={item}
            handleImageClick={onSelect ? handleImageClick : null}
            selectedImage={selectedImage}
          />
        ))}
        {gallery.length === 0 && (
          <p>
            <T id="media_gallery.no_media" />
          </p>
        )}
      </div>
    </S.GalleryWrapper>
  );
});
