import { observer } from "mobx-react-lite";
import { Form } from "react-final-form";
import { FieldText } from "ui/FieldText";
import { GeneralEdit } from "ui/common-styles";
import { PanelActions } from "../../PanelActions";

import { T } from "util/Translation";
import { useTranslation } from "react-i18next";

export const ThemeColor = observer(({ project, updateSettings }) => {
  const { t } = useTranslation();
  const projectId = project.id;
  const projectPublicSettings = project.public_settings;

  const onSubmit = async (data) => {
    await updateSettings(projectId, data);
  };

  return (
    <GeneralEdit>
      <h4>
        <T id="theme_color.title" />
      </h4>
      <p>
        <T id="theme_color.description" />
      </p>

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, form }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FieldText
                label={t("theme_color.label")}
                finalFormProps={{
                  name: "color",
                  initialValue: projectPublicSettings.color || "#6366f1",
                }}
                semanticProps={{
                  type: "color",
                  className: "ls-color-picker",
                  disabled: submitting,
                }}
              />

              <PanelActions />
            </form>
          );
        }}
      />
    </GeneralEdit>
  );
});
