import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  table {
    border-spacing: 0px;
    th {
      font-family: var(--title-font);
      border-bottom: 1px solid var(--grey-300);
    }
    th,
    td {
      text-align: left;
      padding: 8px 16px;
    }
  }
`;

export const ProjectsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px; 
    max-width: min(calc(100% - 96px), 1200px);
}
`;

export const ProjectPreviewWrapper = styled.div`
  display: flex;
  background: white;
  width: 236px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  padding: 8px;
  gap: 12px;
  cursor: pointer;
  color: var(--grey-500);
  box-shadow: var(--shadow-200);
  border: 1px solid var(--grey-300);
  transition: 0.2s;

  &:active {
    border-color: var(--tertiary-500);
  }

  .ls-visual-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 186px;
    border-radius: 4px;
    background: ${({ primary }) =>
      primary ? "var(--primary-500)" : "#eef2ff"};
    border: 1px solid
      ${({ primary }) =>
        primary ? "var(--primary-600)" : "var(--secondary-200)"};
    svg {
      height: 100px;
      width: 100px;
      fill: var(--white);
    }
  }

  .ls-content-preview {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: space-between;
    height: 88px;
    padding: 0 8px;

    > div {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .ls-name {
      font-size: 16px;
      font-weight: 700;
      color: ${({ primary }) =>
        primary ? "var(--primary-600)" : "var(--secondary-600)"};
      margin: 0;
    }

    .ls-desc {
      font-size: 14px;
      color: var(--grey-600);
      margin: 0;
    }

    .ls-time {
      font-size: 14px;
      text-align: right;
      color: var(--grey-500);
      margin: 0;
    }
  }

  &:hover {
    box-shadow: var(--shadow-400);
  }
`;
