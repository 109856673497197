import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { observer } from "mobx-react-lite";
import { DraggableBlock } from "./DraggableBlock";
import { AnchorBlock } from "./AnchorBlock";
import { useStore } from "structure";

export const Droppable = observer(({ id }) => {
  const { formEditorStore } = useStore();
  const { setNodeRef } = useDroppable({
    id,
  });
  return (
    <SortableContext
      id={id}
      items={formEditorStore.blocksOrder}
      strategy={verticalListSortingStrategy}
    >
      <div ref={setNodeRef} className="ls-blocks">
        {formEditorStore.blocksOrder.map((blockId) => (
          <DraggableBlock blockId={blockId} key={`block-${blockId}`} />
        ))}
        <AnchorBlock id="end" />
      </div>
    </SortableContext>
  );
});
