export function makeid(len = 5) {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  var charactersLength = characters.length;
  for (var i = 0; i < len; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result + Date.now();
}

export function formatDate(initialDate, type) {
  let date = new Date(initialDate);

  let month = date.getMonth() + 1;
  let day = date.getDate();
  let year = date.getFullYear();
  let hour = "" + date.getHours();
  let min = "" + date.getMinutes();

  if (hour.length < 2) hour = "0" + hour;
  if (min.length < 2) min = "0" + min;

  if (type === "date") return day + "/" + month + "/" + year;

  return day + "/" + month + "/" + year + " " + hour + ":" + min;
}

export function formatSystemDate(initialDate, type) {
  let date = new Date(initialDate);
  if (type === "date") {
    let month = "" + (date.getMonth() + 1);
    let day = "" + date.getDate();
    let year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  if (type === "datetime")
    return (
      date.getFullYear() +
      "/" +
      date.getMonth() +
      "/" +
      date.getDate() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes()
    );
}

export const calcSize = (data) => {
  if (data.size === "px") return [data.height || 300, data.width || 300];
  if (data.size === "percent")
    return [`${data.height || 50}%`, `${data.width || 50}%`];
  return [300, 300];
};

export const isDeepEqual = (obj1, obj2) => {
  if (obj1 === obj2) return true;
  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  )
    return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (let key of keys1) {
    if (!keys2.includes(key) || !isDeepEqual(obj1[key], obj2[key]))
      return false;
  }

  return true;
};

export const unwrapProxies = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    return obj; // Return primitive values or null unchanged
  }

  // Check if obj is a proxy and unwrap if necessary
  if (Array.isArray(obj.proxyTarget)) {
    return obj.proxyTarget.map(unwrapProxies); // Unwrap array elements
  } else if (typeof obj === "object") {
    const unwrappedObj = {};
    for (let key in obj) {
      unwrappedObj[key] = unwrapProxies(obj[key]); // Recursively unwrap nested objects
    }
    return unwrappedObj;
  }

  return obj; // Return unchanged if not a proxy
};

export function formatSubmissionDateTime(dateTimeStr) {
  const inputDate = new Date(dateTimeStr);
  const today = new Date();

  const inputDateCopy = new Date(inputDate);
  const todayCopy = new Date(today);

  inputDateCopy.setHours(0, 0, 0, 0);
  todayCopy.setHours(0, 0, 0, 0);

  const isToday = inputDateCopy.getTime() === todayCopy.getTime();

  const hours = String(inputDate.getHours()).padStart(2, "0");
  const minutes = String(inputDate.getMinutes()).padStart(2, "0");

  if (isToday) {
    return `${hours}:${minutes}`;
  } else {
    const day = String(inputDate.getDate()).padStart(2, "0");
    const month = String(inputDate.getMonth() + 1).padStart(2, "0");
    const year = String(inputDate.getFullYear()).slice(-2);
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }
}

export function shortenString(str, maxLength = 50) {
  if (typeof str !== "string") return "";
  if (str.length > maxLength) {
    return str.slice(0, maxLength - 3) + "..."; // Subtract 3 for the ellipsis
  }
  return str;
}

export function addOneYear(date) {
  const dateCopy = new Date(date);
  dateCopy.setFullYear(dateCopy.getFullYear() + 1);
  return dateCopy;
}

export function addOneMonth(date) {
  const dateCopy = new Date(date);
  dateCopy.setMonth(dateCopy.getMonth() + 1);
  return dateCopy;
}

export function shadeColor(color, percent) {
  if (!color) return "#fff";
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt(R + (255 - R) * (percent / 100));
  G = parseInt(G + (255 - G) * (percent / 100));
  B = parseInt(B + (255 - B) * (percent / 100));

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  R = R > 0 ? R : 0;
  G = G > 0 ? G : 0;
  B = B > 0 ? B : 0;

  R = Math.round(R);
  G = Math.round(G);
  B = Math.round(B);

  var RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}
