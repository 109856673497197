import { MediaGallery } from "./MediaGallery";

import { T } from "util/Translation";
import * as S from "./styles";

export const Media = () => {
  return (
    <S.Wrapper>
      <div className="main-section">
        <div className="header">
          <h1>
            <T id="media.title" />
          </h1>
          <p>
            <T id="media.description" />
          </p>
        </div>
      </div>
      <MediaGallery />
    </S.Wrapper>
  );
};
