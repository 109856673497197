import { Field, useFormState } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";
import imgPlaceholder from "assets/imgs/imgPlaceholder.png";
import { useTranslation } from "react-i18next";
import * as S from "./styles";

export const Image = ({ blockId }) => {
  const { t } = useTranslation();
  const values = useFormState()?.values?.[blockId]?.["tool-10"] || {};

  return (
    <S.Block>
      <S.Image>
        <img
          className="ls-input-element"
          alt={t("block.image.alt")}
          src={values?.image || imgPlaceholder}
          height={`${values?.height}${values?.size === "percent" ? "%" : ""}`}
          width={`${values?.width}${values?.size === "percent" ? "%" : ""}`}
        />
      </S.Image>
      {values?.desc && (
        <Field name={`${blockId}.tool-10.desc`} initialValue={values.desc}>
          {(props) => (
            <S.DescText>
              <TextareaAutosize
                name={props.input.name}
                value={props.input.value}
                onChange={props.input.onChange}
                className="ls-contentEditable"
              />
            </S.DescText>
          )}
        </Field>
      )}
    </S.Block>
  );
};
