import { observer } from "mobx-react-lite";
import { useStore } from "structure";

import { Loading } from "components/shared/Loading";
import { PersonalDetails } from "./sections/PersonalDetails";
import { Avatar } from "./sections/Avatar";
import { Security } from "./sections/Security";
import { Billing } from "./sections/Billing";

import { T } from "util/Translation";

import * as S from "./styles";

export const Account = observer(() => {
  const { accountStore } = useStore();
  const { isLoading } = accountStore;

  if (isLoading) {
    return (
      <div style={{ height: 400 }}>
        <Loading />
      </div>
    );
  }

  return (
    <S.Wrapper>
      <div className="main-section">
        <div className="header">
          <h1>
            <T id="account.title" />
          </h1>
          <p>
            <T id="account.intro" />
          </p>
        </div>
        <S.SectionWrapper>
          <PersonalDetails />
          <Avatar />
        </S.SectionWrapper>

        <S.SectionWrapper>
          <Security />
        </S.SectionWrapper>

        <S.SectionWrapper>
          <Billing />
        </S.SectionWrapper>
      </div>
    </S.Wrapper>
  );
});
