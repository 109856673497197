import * as S from "./styles";

export const ResponsePreview = ({ data }) => {
  return (
    <S.ResponsePreviewWrapper>
      {data.map((form, idx) => (
        <div key={idx}>
          <h2>{form.name}</h2>
          <ul>
            {form.fields.map((field, s_idx) => (
              <li key={s_idx}>
                <p>{field.field}</p>
                <p>
                  {field.value
                    ? Array.isArray(field.value)
                      ? field.value.join(", ")
                      : field.value
                    : "-"}
                </p>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </S.ResponsePreviewWrapper>
  );
};
