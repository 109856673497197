import { Button, Modal } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";

import { Form } from "react-final-form";
import { FieldText } from "ui/FieldText";
import { ModalCloseWrapper, ModalContentWrapper } from "ui/common-styles";
import { Icon } from "assets/icons/Icon";

import { useStore } from "structure";
import { useTranslation } from "react-i18next";

export const DuplicateProjectModal = observer(
  ({ open, setOpen, project: sourceProject }) => {
    const { projectsStore } = useStore();
    const history = useHistory();
    const { t } = useTranslation();

    const onSubmit = async (data) => {
      const newProjectId = await projectsStore.duplicate({
        name: data.name,
        isPublic: data.public,
        public_settings: sourceProject.public_settings,
        settings: sourceProject.settings,
        forms: sourceProject.forms,
      });
      if (newProjectId) {
        setOpen(false);
        history.push(`/project/${newProjectId}`);
      }
    };

    return (
      <>
        <Modal
          size="tiny"
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          closeIcon={
            <ModalCloseWrapper>
              <Icon name="close" />
            </ModalCloseWrapper>
          }
        >
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <ModalContentWrapper>
                    <div className="ls-content">
                      <h3>{t("duplicate_project_modal.title")}</h3>
                      <p>{t("duplicate_project_modal.description")}</p>
                    </div>
                    <div className="ls-mark">
                      <FieldText
                        label={t(
                          "duplicate_project_modal.duplicated_project_name"
                        )}
                        finalFormProps={{
                          name: "name",
                          initialValue: `${sourceProject.name} - ${t(
                            "duplicate_project_modal.default_suffix"
                          )}`,
                        }}
                        semanticProps={{
                          placeholder: t(
                            "duplicate_project_modal.project_name_placeholder"
                          ),
                        }}
                      />
                    </div>
                    <div className="ls-actions">
                      <Button
                        type="submit"
                        className="ls-bright"
                        primary
                        content={t("duplicate_project_modal.duplicate_button")}
                        disabled={submitting}
                        loading={submitting}
                      />
                      <Button
                        type="button"
                        className="ls-bright"
                        color="grey"
                        content={t("duplicate_project_modal.cancel_button")}
                        disabled={submitting}
                        onClick={() => setOpen(false)}
                      />
                    </div>
                  </ModalContentWrapper>
                </form>
              );
            }}
          />
        </Modal>
      </>
    );
  }
);
