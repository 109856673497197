import { Link, useParams } from "react-router-dom";
import { Icon } from "assets/icons/Icon";
import { AsText } from "ui/common-styles";
import { useEffect, useRef } from "react";

import { T } from "util/Translation";

import { FormPreviewWrapper } from "./styles";

export const FormPreview = ({ data, idx, stage, isVisible, lcoalStore }) => {
  const { projectId } = useParams();
  const indexRef = useRef(idx);
  const order = lcoalStore.order;

  const toggleVisible = async () => {
    lcoalStore.setVisibility(idx, !isVisible);
  };

  const up = async () => {
    lcoalStore.replaceOrder(idx, idx - 1);
  };

  const down = async () => {
    lcoalStore.replaceOrder(idx, idx + 1);
  };

  useEffect(() => {
    indexRef.current = idx;
  }, [idx]);

  const animationClass =
    indexRef.current > idx
      ? "animation-up"
      : indexRef.current < idx
      ? "animation-down"
      : "";

  return (
    <FormPreviewWrapper
      isVisible={isVisible}
      key={idx}
      className={animationClass}
    >
      <Link
        className="ls-form-info"
        to={`/project/${projectId}/form/${data.id}`}
      >
        <div>
          <div className="ls-icon-circle-primary">
            <span>{data.name.substring(0, 1)}</span>
          </div>
          <div className="ls-info">
            <p>
              {isVisible ? (
                <T id="form_preview.visible_stage" />
              ) : (
                <T id="form_preview.hidden_stage" />
              )}
            </p>
            <p className="ls-name">{data.name}</p>
          </div>
        </div>
        <div>
          <div
            className={
              "ls-stage-tag " +
              (isVisible ? "ls-stage-tag-on" : "ls-stage-tag-off")
            }
          >
            {isVisible ? (
              <T id="form_preview.stage_number" values={{ stage }} />
            ) : (
              <T id="form_preview.hidden" />
            )}
          </div>
          <div className="ls-icon-circle-go">
            <Icon name="right" />
          </div>
        </div>
      </Link>

      <div className="ls-form-actions">
        <AsText onClick={toggleVisible}>
          {isVisible ? (
            <T id="form_preview.hide" />
          ) : (
            <T id="form_preview.show" />
          )}{" "}
          <Icon name={isVisible ? "visibilityOff" : "visibility"} />
        </AsText>
        <AsText onClick={up} disabled={idx === 0}>
          <T id="form_preview.move_up" /> <Icon name="circleUp" />
        </AsText>
        <AsText onClick={down} disabled={idx === order.length - 1}>
          <T id="form_preview.move_down" /> <Icon name="circleDown" />
        </AsText>
      </div>
    </FormPreviewWrapper>
  );
};
