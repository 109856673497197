import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .animated-blur-background {
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, var(--grey-100), var(--grey-300));
    background-size: 200% 200%;
    animation: backgroundBlur 5s ease infinite;
    padding: 64px 48px;
    border-radius: 16px;

    span {
      font-size: 48px;
      font-weight: 800;
      font-family: var(--title-font);
      color: var(--grey-600);
    }
  }

  @keyframes backgroundBlur {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;
