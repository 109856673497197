import { observer } from "mobx-react-lite";
import { useForm, useFormState } from "react-final-form";
import { Button } from "semantic-ui-react";

import { T } from "util/Translation";

import * as S from "./styles";

export const PanelActions = observer(() => {
  const { submitting, dirty } = useFormState();
  const { reset } = useForm();
  return (
    <S.ActionsWrapper className={`${dirty ? "ls-dirty" : ""}`}>
      <Button
        className="ls-bright"
        color="grey"
        fluid
        size="small"
        type="button"
        disabled={submitting}
        onClick={() => reset()}
      >
        <T id="panel_actions.cancel" />
      </Button>
      <Button
        primary
        fluid
        size="small"
        type="submit"
        disabled={submitting}
        loading={submitting}
      >
        <T id="panel_actions.apply" />
      </Button>
    </S.ActionsWrapper>
  );
});
