import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "structure";
import { Loading } from "components/shared/Loading";
import { ProjectPreview } from "./ProjectPreview";
import { NewProject } from "./NewProject";
import * as S from "./styles";
import { T } from "util/Translation";
import { Confirm } from "ui/Confirm";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

export const Projects = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { projectsStore } = useStore();
  const [isLoading, setIsLoading] = useState(true);
  const [deleteConfirmId, setDeleteConfirmId] = useState(false);
  const [publishConfirmId, setPublishConfirmId] = useState(false);
  const [draftConfirmId, setDraftConfirmId] = useState(false);

  useEffect(() => {
    projectsStore.fetchProjects().then(() => setIsLoading(false));
    return () => setIsLoading(true);
    // eslint-disable-next-line
  }, []);

  const handleDeleteProject = async (projectId) => {
    await projectsStore.delete(projectId);
    toast.success(t("project.deleted_successfully"));
    history.push(`/projects`);
  };

  const handlePublicProject = async (projectId, isPublic) => {
    await projectsStore
      .update(projectId, { isPublic })
      .then(() => projectsStore.fetchProjects());
    toast.success(isPublic ? t("project.published") : t("project.unpublished"));
  };

  if (isLoading) {
    return (
      <div style={{ height: 400 }}>
        <Loading />
      </div>
    );
  }

  return (
    <S.Wrapper>
      <div className="main-section">
        <div className="header">
          <h1>
            <T id="projects.title" />
          </h1>
          <p>
            <T id="projects.intro" />
          </p>
        </div>
        <S.ProjectsWrapper>
          <NewProject />
          {projectsStore.projects.map((project) => (
            <ProjectPreview
              key={project.id}
              data={project}
              actions={{
                handleDelete: setDeleteConfirmId,
                handlePublish: setPublishConfirmId,
                handleDraft: setDraftConfirmId,
              }}
            />
          ))}
        </S.ProjectsWrapper>
      </div>

      <Confirm
        title={t("project.delete_title")}
        text={t("project.delete_confirmation")}
        confirmButton={t("project.confirm_delete")}
        color="red"
        open={!!deleteConfirmId}
        onCancel={() => setDeleteConfirmId(null)}
        onConfirm={() => handleDeleteProject(deleteConfirmId)}
      />

      <Confirm
        title={t("project.publish_title")}
        text={t("project.publish_confirmation")}
        confirmButton={t("project.confirm_publish")}
        open={!!publishConfirmId}
        onCancel={() => setPublishConfirmId(null)}
        onConfirm={() => handlePublicProject(publishConfirmId, true)}
      />

      <Confirm
        title={t("project.unpublish_title")}
        text={t("project.unpublish_confirmation")}
        confirmButton={t("project.confirm_unpublish")}
        open={!!draftConfirmId}
        onCancel={() => setDraftConfirmId(null)}
        onConfirm={() => handlePublicProject(draftConfirmId, false)}
      />
    </S.Wrapper>
  );
});
