import { Player } from "ui/Player";
import * as S from "./styles";

export const Video = ({ data }) => {
  return (
    <S.Block>
      {data.field && <p>{data.field}</p>}
      <S.Video>
        <Player url={data.url || ""} />
      </S.Video>
    </S.Block>
  );
};
