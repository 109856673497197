import { useStore } from "structure";
import { Form } from "react-final-form";

import { Button } from "semantic-ui-react";
import { Icon } from "assets/icons/Icon";
import { AsText } from "ui/common-styles";
import { FieldText } from "ui/FieldText";

import { useTranslation } from "react-i18next";

export const FormTitleSection = ({
  isEditFormName,
  changeFormName,
  setIsEditFormName,
}) => {
  const { t } = useTranslation();
  const { formEditorStore } = useStore();

  return (
    <div className="ls-form-title">
      {isEditFormName ? (
        <Form
          onSubmit={changeFormName}
          render={({ handleSubmit, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <FieldText
                  finalFormProps={{
                    name: "formName",
                    initialValue: formEditorStore.formName,
                  }}
                  semanticProps={{
                    placeholder: t("form_title_section.form_name_placeholder"),
                    disabled: submitting,
                  }}
                />
                <div className="ls-form-name-actions">
                  <Button
                    basic
                    type="button"
                    size="small"
                    onClick={() => setIsEditFormName(false)}
                  >
                    {t("form_title_section.cancel_button")}
                  </Button>
                  <Button primary type="submit" size="small">
                    {t("form_title_section.save_button")}
                  </Button>
                </div>
              </form>
            );
          }}
        />
      ) : (
        <>
          <h1>{formEditorStore.formName}</h1>
          <AsText
            color="grey"
            className={
              "ls-bright ls-icon" + (isEditFormName ? "ls-active" : "")
            }
            onClick={() => setIsEditFormName(true)}
          >
            <Icon name="edit" />
          </AsText>
        </>
      )}
    </div>
  );
};
