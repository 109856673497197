import { useSortable } from "@dnd-kit/sortable";
import { DropIndicator } from "./DropIndicator";

export const AnchorBlock = ({ id }) => {
  const { setNodeRef, over, active } = useSortable({
    id: `anchor-${id}`,
  });

  const isOverCurrentBlock = over?.id === `anchor-${id}`;
  const isDraggingCurrentBlock = active?.id === `anchor-${id}`;

  return (
    <>
      <div ref={setNodeRef} style={{ height: 40, flexGrow: 1 }}>
        {isOverCurrentBlock && !isDraggingCurrentBlock && (
          <div>
            <DropIndicator />
          </div>
        )}
      </div>
    </>
  );
};
