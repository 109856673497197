import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useStore } from "structure";
import { Container } from "./styles";
import { Login } from "./Login";
import { Register } from "./Register";
import { EmailVerification } from "./EmailVerification";
import { ResetPassword } from "./ResetPassword";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ReactComponent as Logo } from "assets/icons/logo_color.svg";

export const Enter = ({ currentScreen }) => {
  const history = useHistory();
  const { accountStore } = useStore();
  const { currentAccount } = accountStore;
  useEffect(() => {
    if (currentAccount !== null) {
      history.push("");
    }
    // eslint-disable-next-line
  }, []);
  return (
    <Container>
      <div className="ls-bg-elem">
        <Logo height={32} width={152} />
      </div>

      <GoogleOAuthProvider clientId="171609862382-nmdm59oogpn3berrpibd135obgeisu4g.apps.googleusercontent.com">
        {currentScreen === "register" && <Register />}
        {currentScreen === "login" && <Login />}
        {currentScreen === "verify" && <EmailVerification />}
        {currentScreen === "reset" && <ResetPassword />}
      </GoogleOAuthProvider>
    </Container>
  );
};
