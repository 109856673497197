import styled from "styled-components";

export const Wrapper = styled.div`
  border-radius: 8px;
  margin: 0 auto;
  width: 100%;

  :focus,
  :focus-within,
  :target,
  :focus-visible,
  :active {
    > div:first-child {
      // background: var(--grey-100);
    }
  }

  animation: blockAppear 0.5s;

  @keyframes blockAppear {
    0% {
      opacity: 0;
      transform: scale(0.7);
    }

    25% {
      opacity: 0.5;
      transform: scale(1);
    }

    75% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }
`;

export const Block = styled.div`
  padding: 8px 28px;
  min-height: 50px;
  border-radius: 2px;
  border: 1px solid transparent;
  background: var(--grey-50);

  :hover {
    border-color: var(--secondary-500);
  }

  .ls-contentEditable {
    padding: 0;
    border: none;
  }

  :hover,
  :focus,
  :focus-within,
  :active {
    .ql-toolbar.ql-snow {
      display: flex;
      animation: fadeIn 0.3s;
    }
  }
`;

export const ButtonOps = styled.div`
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  height: 0px;
  padding: 20px;
  opacity: 1;
  transition: 0.4s;
`;
