import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionWrapper = styled.div`
    display: grid;
        grid-template-columns: 1fr 1fr;
    gap: 64px; 
    margin-bottom: 40px;
}
`;
