import { Container } from "./styles";
import { useState } from "react";
import { Header } from "./parts/Header";
import { SuccessPage } from "./parts/SuccessPage";
import { MainSection } from "./parts/MainSection";
import { Footer } from "./parts/Footer";
import { ErrorPage } from "./parts/ErrorPage";

const PAGE_STATUSES = {
  FORM: 0,
  SUCCESS: 1,
  ERROR: 2,
};

export const Content = ({ data, setReplyContent = null }) => {
  const [pageStatus, setPageStatus] = useState(PAGE_STATUSES.FORM);

  console.log(data);

  const settings = data.settings;

  return (
    <Container template={settings.template} color={settings.color}>
      <Header data={settings.header} />
      {pageStatus === PAGE_STATUSES.ERROR && (
        <ErrorPage data={settings.error} />
      )}
      {pageStatus === PAGE_STATUSES.SUCCESS && (
        <SuccessPage data={settings.success} />
      )}
      {pageStatus === PAGE_STATUSES.FORM && (
        <MainSection
          data={data}
          setPageStatus={setPageStatus}
          setReplyContent={setReplyContent}
        />
      )}
      <Footer data={data.footer} />
    </Container>
  );
};
