import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { ReactComponent as Logo } from "assets/icons/logo_color.svg";
import { Icon } from "assets/icons/Icon";
import { UserButton } from "./UserButton";

import { T } from "util/Translation";

import * as S from "./styles";
import { MAIN_URL } from "util/consts";

export const Navigation = observer(() => {
  const history = useHistory();
  const location = useLocation();

  const isProjectActive = () => {
    return (
      location.pathname.includes("/project") ||
      location.pathname.includes("/form")
    );
  };

  return (
    <S.SideNavigation>
      <nav>
        <button
          className="ls-logo ls-as-link"
          onClick={() => history.push("/")}
        >
          <Logo height={32} width={88} />
        </button>
        <ul>
          <li>
            <NavLink
              to=""
              isActive={(match, location) =>
                location.pathname === "" || location.pathname === "/"
              }
            >
              <Icon name="home" />
              <span>
                <T id="navigation.home" />
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/projects"
              isActive={(match, location) => match || isProjectActive()}
            >
              <Icon name="tactic" />
              <span>
                <T id="navigation.projects" />
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/media">
              <Icon name="gallery" />
              <span>
                <T id="navigation.media" />
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/submissions">
              <Icon name="send" />
              <span>
                <T id="navigation.replies" />
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/statistics">
              <Icon name="chartBar" />
              <span>
                <T id="navigation.statistics" />
              </span>
            </NavLink>
          </li>
          <li>
            <Link to={{ pathname: `${MAIN_URL}help-center/` }} target="_blank">
              <Icon name="helpCenter" />
              <span>
                <T id="navigation.help_center" />
              </span>
            </Link>
          </li>
        </ul>
      </nav>

      <UserButton />
    </S.SideNavigation>
  );
});
