import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 48px 48px 20px;

  h3 {
    font-family: var(--title-font);
    color: var(--primary);
    font-weight: 700;
    font-size: 64px;
  }

  .ls-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .ls-body {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 24px;

    @media only screen and (max-width: 1100px) {
      grid-template-columns: 1fr;
    }

    > div {
      background: var(--grey-50);
      border: 1px solid var(--grey-200);
      padding: 24px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      height: fit-content;
    }
  }

  @media only screen and (max-width: 700px) {
    padding: 32px 24px 120px;
  }
`;

export const ProjectSectionWrapper = styled.div`
  width: 100%;
  min-width: min(512px, 100%);

  h5 {
    color: var(--secondary-500);
  }

  .ls-projects {
    display: flex;
    flex-direction: row;
    gap: 24px;
    flex-wrap: wrap;
    margin-bottom: 8px;
  }

  .ls-more-projects {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    a {
      font-family: var(--title-font);
      color: var(--secondary-600);
      display: flex;
      align-items: center;
      text-decoration: none;

      svg {
        fill: var(--secondary-600);
        margin-bottom: -2px;
      }
    }
  }

  .ls-public,
  .ls-draft {
    padding: 4px 16px;
    border-radius: 4px;
    line-height: 1.5;
  }

  .ls-public {
    color: var(--green-700);
    background: var(--green-100);
  }

  .ls-draft {
    color: var(--grey-700);
    background: var(--grey-100);
  }
`;

export const TipsSectionWrapper = styled.div`
  width: 100%;
  min-width: 200px;

  .ls-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > .ui.button {
      margin-top: 40px;
    }
  }

  .ls-article {
    display: flex;
    flex-direction: column;
    gap: 4px;

    h6 {
      color: var(--secondary-600);
    }

    p {
      color: var(--grey-500);
      margin-bottom: 0;
    }

    .ui.button {
      padding: 4px 4px 4px 12px;
    }
  }
`;
