import { Button } from "semantic-ui-react";
import { useStore } from "structure";
import { Link } from "react-router-dom";

import { T } from "util/Translation";
import { useTranslation } from "react-i18next";

import * as S from "./styles";

export const SubscriptionCompleted = () => {
  const { t } = useTranslation();
  const { accountStore } = useStore();
  const { currentAccount } = accountStore;

  const planName = {
    BASIC: "subscription_success.plan_basic",
    PREMIUM: "subscription_success.plan_premium",
    PRO: "subscription_success.plan_pro",
  };

  return (
    <S.Wrapper>
      <h1>
        <T id="subscription_success.thank_you_title" />
      </h1>
      <h3>
        <T id="subscription_success.success_message" />
      </h3>
      <h4>
        <T
          id="subscription_success.enjoy_plan"
          values={{ plan: t(planName[currentAccount.subscription_type]) }}
          components={{ strong: <strong /> }}
        />
      </h4>
      <Button as={Link} to="/" primary size="big">
        <T id="subscription_success.go_home_button" />
      </Button>
    </S.Wrapper>
  );
};
