import { observer } from "mobx-react-lite";
import { useStore } from "structure";
import { API_URL } from "util/consts";

export const UserAvatarView = observer(() => {
  const { accountStore } = useStore();
  const { currentAccount } = accountStore;

  if (currentAccount?.avatar) {
    return (
      <img
        src={`${API_URL}media/${currentAccount.avatar}`}
        referrerPolicy="no-referrer"
        alt="avatar"
      />
    );
  }

  return (
    <span className="ls-avatar">{currentAccount?.username?.slice(0, 2)}</span>
  );
});
