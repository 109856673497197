import { Field, useFormState } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";
import { FieldText } from "ui/FieldText";
import * as S from "./styles";
import { useTranslation } from "react-i18next";

export const Text = ({ blockId }) => {
  const { t } = useTranslation();
  const values = useFormState()?.values?.[blockId]?.["tool-2"] || {};

  return (
    <S.Block>
      <Field name={`${blockId}.tool-2.field`} initialValue={values.field || ""}>
        {(props) => (
          <TextareaAutosize
            name={props.input.name}
            value={props.input.value}
            onChange={props.input.onChange}
            className="ls-contentEditable"
            placeholder={t("block.text.field_placeholder")}
          />
        )}
      </Field>
      <Field name={`${blockId}.tool-2.desc`} initialValue={values.desc || ""}>
        {(props) => (
          <S.Field>
            <TextareaAutosize
              name={props.input.name}
              value={props.input.value}
              onChange={props.input.onChange}
              className="ls-contentEditable"
              placeholder={t("block.text.desc_placeholder")}
            />
          </S.Field>
        )}
      </Field>
      <FieldText
        finalFormProps={{
          name: `${blockId}-preview`,
        }}
        semanticProps={{
          placeholder: values.placeholder,
          type: values.type,
        }}
      />
    </S.Block>
  );
};
