import { ContentPart } from "./ContentPart";

export const Header = ({ data }) => {
  if (!data) return null;

  return (
    <header>
      <div>
        <ContentPart id="header" fields={data.fields} />
      </div>
    </header>
  );
};
