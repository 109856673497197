import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DropIndicator } from "./DropIndicator";
import { useStore } from "structure";
import { observer } from "mobx-react-lite";
import { Block } from "../Block";
import { Icon } from "assets/icons/Icon";

export const DraggableBlock = observer(({ blockId }) => {
  const { formEditorStore } = useStore();
  const { blockEditId, blocks } = formEditorStore;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    over,
    active,
  } = useSortable({
    id: `block-${blockId}`,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: isDragging ? 0 : 1,
  };

  const isFocused = blockEditId === blockId;
  const isOverCurrentBlock = over?.id === `block-${blockId}`;
  const isDraggingCurrentBlock = active?.id === `block-${blockId}`;
  const block = blocks.get(blockId);

  return (
    <>
      {isOverCurrentBlock && !isDraggingCurrentBlock && <DropIndicator />}
      <div
        ref={setNodeRef}
        style={style}
        className={`block${isFocused ? " ls-focused" : ""}`}
      >
        <div className="block-drag">
          <button {...attributes} {...listeners}>
            <Icon name="drag" height={20} />
          </button>
        </div>
        <Block blockId={blockId} type={block?.type} />
      </div>
    </>
  );
});

export const BlockItem = () => {
  const { formEditorStore } = useStore();
  const { blocks, activeBlockId } = formEditorStore;
  const block = blocks.get(activeBlockId);

  if (block?.type) {
    return (
      <div className="block active-drag">
        <div className="block-drag"></div>
        <Block blockId={activeBlockId} type={block?.type} />
      </div>
    );
  }
  console.error("not block", block);
  return null;
};
