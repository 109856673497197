import { observer } from "mobx-react-lite";
import { Form } from "react-final-form";
import { FieldText } from "ui/FieldText";
import { GeneralEdit } from "ui/common-styles";
import { useStore } from "structure";
import { PanelActions } from "../../PanelActions";

import { T } from "util/Translation";
import { useTranslation } from "react-i18next";

export const Name = observer(({ project }) => {
  const { projectsStore } = useStore();
  const { t } = useTranslation();
  const projectId = project.id;

  const onSubmit = async (data) => {
    await projectsStore
      .update(projectId, { name: data.name })
      .then(() => projectsStore.fetchProjects());
  };

  return (
    <GeneralEdit>
      <h4>
        <T id="name.title" />
      </h4>
      <p>
        <T id="name.description" />
      </p>

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FieldText
                label={t("name.label")}
                finalFormProps={{
                  name: "name",
                  initialValue: project.name,
                }}
                semanticProps={{
                  disabled: submitting,
                }}
              />

              <PanelActions />
            </form>
          );
        }}
      />
    </GeneralEdit>
  );
});
