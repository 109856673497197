import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--white);
  padding: 108px 0;
  margin: 0 auto;

  .ls-bg-elem {
    position: absolute;
    height: calc(100vh - 32px);
    width: 40vw;
    top: 16px;
    left: 16px;
    border-radius: 16px;
    background: var(--secondary-100);

    > svg {
      position: absolute;
      top: 16px;
      left: 20px;
    }
  }

  .ls-outlet-wrapper {
    background: #ffffff66;
    border-radius: 8px;
    border: 1px solid var(--secondary-200);
    padding: 24px 32px;
    backdrop-filter: blur(8px);
    margin: auto 32px;
    max-width: calc(100vw - 64px);
  }

  .ls-message {
    margin-top: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    gap: 8px;
    z-index: 9;
    background: rgba(255, 255, 255, 0.6);
    border: 1px solid var(--grey-200);
    border-radius: 6px;
    padding: 24px;
    backdrop-filter: blur(4px);

    p {
      margin: 0;
      color: var(--grey-500);
    }
    a {
      color: var(--grey-800);
      text-decoration: none;
      font-weight: 700;
      :hover {
        color: var(--grey-900);
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .ls-message {
      bottom: -40px;
      top: auto;
    }
  }

  .ls-login,
  .ls-register,
  .ls-complete,
  .ls-confirm,
  .ls-reset-password {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-direction: column;

    h1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      color: var(--primary-500);
      margin-bottom: 16px;
      span {
        font-size: 32px;
        font-weight: 700;
      }
    }
  }

  .ls-body {
    display: flex;
    gap: 16px;
    width: 400px;
    max-width: 100%;
    align-items: stretch;
    flex-direction: column;
  }

  .ls-section-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ls-complete .ls-body {
    width: 642px;
  }

  .ls-reset-password .ls-body.ls-details {
    grid-template-columns: 1fr;
    width: 360px;
  }

  .ui.form {
    .ls-submit-error {
      color: var(--toastify-color-error);
      text-align: center;
      background: #ffe9e7;
      margin: 12px 0;
    }
    > .ui.button {
      margin-top: 24px;
    }
  }

  .ls-register-types {
    display: flex;
    flex-direction: column;
    gap: 24px;

    button {
      backgorund: var(--grey-50);
      border: 1px solid var(--grey-200);
      color: var(--grey-700);
      padding: 16px 64px;
    }
  }

  .ls-confirm {
    .ls-email span {
      font-weight: 600;
      color: var(--secondary-600);
    }

    .ls-resend {
      display: flex;
      align-items: center;
      gap: 12px;

      p {
        margin: 0;
      }
      .button {
        padding: 4px 0;
      }
    }
  }

  .ls-disclaimer {
    text-align: center;
    font-size: 0.9rem;
    text-wrap-style: pretty;
    padding: 8px;
    a {
      font-size: 0.9rem;
    }
  }
`;

export const GoogleAccountWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--grey-300);

  > img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }

  .ls-account-content {
    font-size: 1.17em;
  }
`;
