import { useStore } from "structure";
import { useState } from "react";
import { toast } from "react-toastify";
import { Button } from "semantic-ui-react";
import { Form } from "react-final-form";
import { Icon } from "assets/icons/Icon";
import { ButtonWithIcon } from "ui/common-styles";
import { FieldText } from "ui/FieldText";
import { equals, max, min, multiValidators, required } from "util/validators";

import { useTranslation } from "react-i18next";
import { T } from "util/Translation";

import * as S from "./styles";

export const Security = () => {
  const { t } = useTranslation();
  const { accountStore } = useStore();
  const [isEditing, setIsEditing] = useState(false);
  let formRef = null;

  const onSubmit = async (data) => {
    const status = await accountStore.updatePassword(data);

    if (status) {
      if (status === "Error") {
        return { currentPass: t("account_security.incorrect_pass") };
      } else {
        toast.success(t("account_security.update_successfully"));
      }
    } else {
      toast.error(t("account_security.update_failed"));
    }
    handleCancel();
  };

  const handleCancel = () => {
    formRef?.reset();
    setIsEditing(false);
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, form }) => {
        formRef = form;
        return (
          <form onSubmit={handleSubmit}>
            <S.FieldsSectionWrapper>
              <h4>
                <T id="account_security.title" />
              </h4>
              <ul>
                {isEditing ? (
                  <>
                    <li>
                      <label>
                        <T id="account_security.current_pass" />
                      </label>
                      <FieldText
                        finalFormProps={{
                          name: "currentPass",
                          validate: multiValidators([
                            required,
                            min(8),
                            max(255),
                          ]),
                        }}
                        semanticProps={{
                          placeholder: t("account_security.current_pass"),
                          type: "password",
                          disabled: submitting,
                        }}
                      />
                    </li>
                    <li>
                      <label>
                        <T id="account_security.new_pass" />
                      </label>
                      <FieldText
                        finalFormProps={{
                          name: "newPass",
                          validate: multiValidators([
                            required,
                            min(8),
                            max(255),
                          ]),
                        }}
                        semanticProps={{
                          placeholder: t("account_security.new_pass"),
                          type: "password",
                          disabled: submitting,
                        }}
                      />
                    </li>
                    <li>
                      <label>
                        <T id="account_security.confirm_pass" />
                      </label>
                      <FieldText
                        finalFormProps={{
                          name: "confirmPass",
                          validate: multiValidators([
                            required,
                            min(8),
                            max(255),
                            equals("newPass"),
                          ]),
                        }}
                        semanticProps={{
                          placeholder: t("account_security.confirm_pass"),
                          type: "password",
                          disabled: submitting,
                        }}
                      />
                    </li>
                  </>
                ) : (
                  <li>
                    <label>
                      <T id="account_security.pass" />
                    </label>
                    <p>********</p>
                  </li>
                )}
              </ul>
              <div className="ls-actions">
                {isEditing ? (
                  <>
                    <Button
                      type="button"
                      className="ls-bright"
                      color="grey"
                      disabled={submitting}
                      onClick={handleCancel}
                    >
                      <T id="account_security.cancel" />
                    </Button>

                    <Button type="submit" primary disabled={submitting}>
                      <T id="account_security.update" />
                    </Button>
                  </>
                ) : (
                  <ButtonWithIcon
                    basic
                    primary
                    type="button"
                    onClick={() => setIsEditing(true)}
                  >
                    <Icon name="password" height={18} />
                    <T id="account_security.change" />
                  </ButtonWithIcon>
                )}
              </div>
            </S.FieldsSectionWrapper>
          </form>
        );
      }}
    />
  );
};
