import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";

import { useStore } from "structure";
import { Form } from "react-final-form";

import { Button, Modal } from "semantic-ui-react";
import { FieldText } from "ui/FieldText";
import { ModalCloseWrapper, ModalContentWrapper } from "ui/common-styles";
import { Icon } from "assets/icons/Icon";

import { useTranslation } from "react-i18next";
import { T } from "util/Translation";

export const DuplicateFormModal = observer(
  ({ open, setOpen, projectId, sourceForm }) => {
    const { t } = useTranslation();
    const { projectsStore } = useStore();
    const history = useHistory();

    const onSubmit = async (data) => {
      const newForm = await projectsStore.duplicateForm(projectId, {
        ...sourceForm,
        name: data.name,
      });
      setOpen(false);
      history.push(`/project/${projectId}/form/${newForm}`);
    };

    return (
      <>
        <Modal
          size="tiny"
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          closeIcon={
            <ModalCloseWrapper>
              <Icon name="close" />
            </ModalCloseWrapper>
          }
        >
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <ModalContentWrapper>
                    <div className="ls-content">
                      <h3>
                        <T id="duplicate_form_modal.title" />
                      </h3>
                      <p>
                        <T id="duplicate_form_modal.description" />
                      </p>
                      <div className="ls-mark">
                        <FieldText
                          label={t("duplicate_form_modal.duplicated_form_name")}
                          finalFormProps={{
                            name: "name",
                            initialValue: `${sourceForm.name} - ${t(
                              "duplicate_form_modal.default_suffix"
                            )}`,
                          }}
                          semanticProps={{
                            placeholder: t(
                              "duplicate_form_modal.form_name_placeholder"
                            ),
                          }}
                        />
                      </div>
                    </div>
                    <div className="ls-actions">
                      <Button
                        type="submit"
                        className="ls-bright"
                        primary
                        content={t("duplicate_form_modal.duplicate_button")}
                        disabled={submitting}
                        loading={submitting}
                      />
                      <Button
                        type="button"
                        className="ls-bright"
                        color="grey"
                        content={t("duplicate_form_modal.cancel_button")}
                        disabled={submitting}
                        onClick={() => setOpen(false)}
                      />
                    </div>
                  </ModalContentWrapper>
                </form>
              );
            }}
          />
        </Modal>
      </>
    );
  }
);
