import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import { Container } from "./styles";
import { useStore } from "structure";
import { useMemo, useState } from "react";
import { Button } from "semantic-ui-react";
import { Form } from "react-final-form";
import { formatDate } from "util/helpers";
import {
  AsLink,
  PaymentSummaryWrapper,
  PlanOptionsWrapper,
} from "ui/common-styles";
import { ReactComponent as Logo } from "assets/icons/logo_color.svg";
import { MAIN_URL, PLANS } from "util/consts";
import { observer } from "mobx-react-lite";
import { T } from "util/Translation";
import { useTranslation } from "react-i18next";

const PLAN_DETAILS = {
  premium: {
    name: PLANS.premiumMonthly.name,
    m_price: PLANS.premiumMonthly.price,
    y_price: PLANS.premiumAnnualy.price,
    m_id: PLANS.premiumMonthly.pp,
    y_id: PLANS.premiumAnnualy.pp,
  },
  pro: {
    name: PLANS.proMonthly.name,
    m_price: PLANS.proMonthly.price,
    y_price: PLANS.proAnnualy.price,
    m_id: PLANS.proMonthly.pp,
    y_id: PLANS.proAnnualy.pp,
  },
};

export const Payment = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { plan } = useParams();
  const { accountStore, paymentsStore } = useStore();
  const { currentAccount } = accountStore;
  const [selectedPlan, setSelectedPlan] = useState("month");
  const planDetails = PLAN_DETAILS[plan];

  const onSubmit = async () => {
    const planId = planDetails[selectedPlan === "month" ? "m_id" : "y_id"];
    const res = await paymentsStore.subscribe({ planId, currentAccount });
    if (res?.approval_url) {
      window.location.href = res?.approval_url;
    }
  };

  const logout = () => {
    accountStore.logout();
  };

  if (!planDetails) {
    return <Redirect to="/" />;
  }

  const startDate = new Date();
  const endDate = useMemo(
    () => addMonthsToDate(startDate, selectedPlan === "month" ? 1 : 12),
    // eslint-disable-next-line
    [selectedPlan]
  );

  const start = formatDate(startDate, "date");
  const end = formatDate(endDate, "date");

  return (
    <Container>
      <div className="ls-header">
        <button
          className="ls-logo ls-as-link"
          onClick={() => history.push("/")}
        >
          <Logo height={32} width={88} />
        </button>
      </div>

      <div className="ls-body">
        <h1>
          <T
            id="payment.create_subscription"
            values={{ planName: t(planDetails.name) }}
            components={{ 1: <strong /> }}
          />
        </h1>
        <div className="ls-account">
          <h4>
            <T id="payment.account" />
          </h4>
          {currentAccount ? (
            <div className="row">
              <img
                src={currentAccount?.avatar}
                referrerPolicy="no-referrer"
                alt="avatar"
              />
              <div className="col">
                <h5>{currentAccount?.username}</h5>
                <p>{currentAccount?.email}</p>
                <p className="ls-note">
                  <T id="payment.logout_notice" />
                  <AsLink onClick={logout}>
                    <T id="payment.logout" />
                  </AsLink>
                  .
                </p>
              </div>
            </div>
          ) : (
            <div className="col">
              <div className="row">
                <p>
                  <T id="payment.login_prompt" />
                </p>
                <Button secondary size="small" as={Link} to="/login">
                  <T id="payment.login_button" />
                </Button>
              </div>
              <div className="row">
                <p>
                  <T id="payment.register_prompt" />
                </p>
                <Button secondary size="small" as={Link} to="/register">
                  <T id="payment.register_button" />
                </Button>
              </div>
            </div>
          )}
        </div>

        <div className="ls-cycle">
          <h4>
            <T id="payment.recurring_cycle" />
          </h4>
          <PlanOptionsWrapper>
            <Button
              className={selectedPlan === "year" ? "ls-selected" : ""}
              type="button"
              onClick={() => setSelectedPlan("year")}
            >
              <h3>
                <T id={planDetails.name} />
              </h3>
              <h5>
                <T id="payment.annually" />
              </h5>
              <div className="price">
                <span className="p-m">$</span>
                <span className="p-l">{planDetails.y_price}</span>
                <span className="p-s">/mo</span>
              </div>
            </Button>
            <Button
              className={selectedPlan === "month" ? "ls-selected" : ""}
              type="button"
              onClick={() => setSelectedPlan("month")}
            >
              <h3>
                <T id={planDetails.name} />
              </h3>
              <h5>
                <T id="payment.monthly" />
              </h5>
              <div className="price">
                <span className="p-m">$</span>
                <span className="p-l">{planDetails.m_price}</span>
                <span className="p-s">/mo</span>
              </div>
            </Button>
          </PlanOptionsWrapper>
        </div>

        <div className="ls-payment">
          <h4>
            <T id="payment.payment" />
          </h4>
          <PaymentSummaryWrapper>
            <li>
              <p>
                <T
                  id="payment.plan_summary"
                  values={{ planName: t(planDetails.name), start, end }}
                  components={{ 1: <span /> }}
                />
              </p>
              <p>
                {selectedPlan === "year"
                  ? planDetails.y_price * 12
                  : planDetails.m_price}
                $
              </p>
            </li>
            <li className="ls-sum">
              <p>
                <T id="payment.total_cost" />
              </p>
              <p>
                {selectedPlan === "year"
                  ? planDetails.y_price * 12
                  : planDetails.m_price}
                $
              </p>
            </li>
          </PaymentSummaryWrapper>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <div className="ls-actions">
                  <Button
                    primary
                    fluid
                    type="submit"
                    disabled={submitting}
                    loading={submitting}
                  >
                    <T id="payment.subscribe_button" />
                  </Button>
                </div>
              </form>
            )}
          />
        </div>

        <p className="ls-disclaimer">
          <T
            id="payment.agreement"
            components={{
              1: (
                <Link
                  to={{ pathname: `${MAIN_URL}terms-of-use/` }}
                  target="_blank"
                />
              ),
              2: (
                <Link
                  to={{ pathname: `${MAIN_URL}privacy-policy/` }}
                  target="_blank"
                />
              ),
            }}
          />
        </p>
      </div>
    </Container>
  );
});

const addMonthsToDate = (date, months) => {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + months);
  if (newDate.getDate() !== date.getDate()) {
    newDate.setDate(0);
  }
  return newDate;
};
