import { useState } from "react";
import { ProjectPreviewWrapper } from "./styles";
import { Icon } from "assets/icons/Icon";
import { CreateProjectModal } from "components/shared/CreateProjectModal";
import { T } from "util/Translation";

export const NewProject = () => {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(true);
  };

  return (
    <>
      <ProjectPreviewWrapper
        primary
        onClick={onClick}
        className="ls-new-project"
      >
        <Icon name="plus" />
        <h4>
          <T id="project.create_new" />
          <Icon name="right" />
        </h4>

        <p>
          <T id="project.create_new_text" />
        </p>
      </ProjectPreviewWrapper>

      <CreateProjectModal open={open} setOpen={setOpen} />
    </>
  );
};
