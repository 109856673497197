import styled from "styled-components";

export const Block = styled.div`
  & > div {
    margin: 12px 0;
  }

  .ls-contentEditable {
    display: block;
    border: 1px solid transparent;
    padding: 8px 16px;
    border-radius: 4px;
    width: 100%;
    font-family: inherit;
    font-size: 16px;
    line-height: 24px;
    box-sizing: border-box;
    resize: none;
    background: transparent;
  }
  .ls-contentEditable:hover {
    border-color: #d4c3c3;
  }
  .ls-contentEditable:focus-visible {
    outline: 0px;
    border-color: #a47c7c;
  }
  .ls-field {
    .ls-contentEditable:hover {
      border-color: #94c0ca;
    }
    .ls-contentEditable:focus-visible {
      border-color: #31a5be;
    }
  }
`;

export const TitleText = styled.div`
  .ls-contentEditable {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
  }
`;

export const Field = styled.div`
  .ls-contentEditable {
    /*background: #f4f1f1;*/
  }

  .ls-field {
    background: #f4f1f1;
  }
`;

export const DescText = styled.div``;

export const RadioOptions = styled.div`
  h4 {
    font-weight: 600;
  }
  .ls-field {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    .ls-contentEditable {
      border-color: #d4c3c3;
    }

    .remove-option {
      display: flex;
      visibility: hidden;
      position: relative;
      height: 20px;
      width: 20px;
      padding: 0;
      align-items: center;
      justify-content: center;
      float: right;
      i {
        margin: 0 !important;
      }
    }
  }

  .ls-field:hover .remove-option {
    display: flex;
    visibility: visible;
  }
`;

export const DropdownOptions = styled.div`
  h4 {
    font-weight: 600;
  }
  .ls-field {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    .ls-contentEditable {
      border-color: #d4c3c3;
    }
    .option-demo {
      position: relative;
      border: 1px solid #d4c3c3;
      border-radius: 20px;
      padding: 2px 8px;
    }
    .remove-option {
      display: flex;
      visibility: hidden;
      position: relative;
      height: 20px;
      width: 20px;
      padding: 0;
      align-items: center;
      justify-content: center;
      float: right;
      i {
        margin: 0 !important;
      }
    }
  }

  .ls-field:hover .remove-option {
    display: flex;
    visibility: visible;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;

  .selectField {
    grid-column-start: 1;
  }
  .pickerField {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

export const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  .ls-input-element {
    border: 1px solid transparent;
    cursor: pointer;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
`;

export const Gallery = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border-radius: 4px;
`;

export const SplitWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ splitTemplate, gap }) =>
    splitTemplate === 30
      ? `3fr 7fr`
      : splitTemplate === 100
      ? `1fr`
      : splitTemplate === 70
      ? `7fr 3fr`
      : "1fr 1fr"};
  gap: ${({ gap }) => gap || "16px"};

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

export const RichText = styled.div`
  .ql-container {
    font-family: var(--primary-font);
    font-size: 16px;
    border: none;
  }

  .quill {
    margin-top: 0px;
  }

  .ql-toolbar.ql-snow {
    position: relative;
    top: -10px;
    left: -36px;
    z-index: 1;
    width: fit-content;
    display: none;
    flex-direction: row;
    gap: 8px;
    flex-wrap: nowrap;
    margin-top: -42px;
    margin-left: 20px;
    border-radius: 8px;
    border: 1px solid var(--secondary-300);
    background: var(--white);
    box-shadow: var(--shadow-200);

    button:hover,
    button:focus,
    button.ql-active,
    .ql-picker-label:hover,
    .ql-picker-label.ql-active,
    .ql-picker-item:hover,
    .ql-picker-item.ql-selected {
      color: var(--secondary-500);

      .ql-stroke,
      .ql-stroke-miter {
        stroke: var(--secondary-500);
      }

      .ql-fill {
        fill: var(--secondary-500);
      }
    }

    .ql-formats {
      display: flex;
      flex-direction: row;
      margin-right: 0;
    }
  }

  .ql-editor {
    padding: 0;
  }
`;
