import { Field, useFormState } from "react-final-form";
import { Player } from "ui/Player";
import TextareaAutosize from "react-textarea-autosize";

import * as S from "./styles";

export const Video = ({ blockId }) => {
  const values = useFormState()?.values?.[blockId]?.["tool-12"];

  return (
    <S.Block>
      <Player url={values?.url || ""} />
      {values?.desc && (
        <Field name={`${blockId}.tool-12.desc`}>
          {(props) => (
            <S.DescText>
              <TextareaAutosize
                name={props.input.name}
                value={props.input.value}
                onChange={props.input.onChange}
                className="ls-contentEditable"
              />
            </S.DescText>
          )}
        </Field>
      )}
    </S.Block>
  );
};
