import { Field, useField } from "react-final-form";
import * as S from "./styles";
import imgPlaceholder from "assets/imgs/imgPlaceholder.png";
import TextareaAutosize from "react-textarea-autosize";
import { Dropdown, Input } from "semantic-ui-react";
import { MediaGalleryModal } from "components/shared/MediaGalleryModal";

import { useTranslation } from "react-i18next";
import { T } from "util/Translation";

export const ImageSettings = ({ blockId }) => {
  const { t } = useTranslation();

  const sizeOptions = [
    {
      key: "default",
      text: t("image_settings.size.default"),
      value: "default",
    },
    {
      key: "px",
      text: t("image_settings.size.px"),
      value: "px",
    },
    {
      key: "percent",
      text: t("image_settings.size.percent"),
      value: "percent",
    },
  ];

  const { input: size } = useField(`${blockId}.tool-10.size`);
  const { input: height } = useField(`${blockId}.tool-10.height`);
  const { input: width } = useField(`${blockId}.tool-10.width`);

  return (
    <S.SectionWrapper>
      <li>
        <Field name={`${blockId}.tool-10.image`}>
          {(props) => (
            <>
              <label>
                <T id="image_settings.select_image" />
              </label>
              <S.Image>
                <input
                  name={props.input.name}
                  value={props.input.value}
                  onChange={props.input.onChange}
                  type="hidden"
                />
                <MediaGalleryModal
                  onSelect={(data) => {
                    props.input.onChange(data);
                  }}
                  initialValue={props.input.value}
                  trigger={
                    <img
                      className="ls-input-element"
                      alt={t("image_settings.alt_text")}
                      src={props.input.value || imgPlaceholder}
                      width="100%"
                    />
                  }
                />
              </S.Image>
            </>
          )}
        </Field>
      </li>
      <li>
        <Field name={`${blockId}.tool-10.desc`}>
          {(props) => (
            <S.DescText>
              <label>
                <T id="image_settings.description" />
              </label>
              <TextareaAutosize
                name={props.input.name}
                value={props.input.value}
                onChange={props.input.onChange}
                className="ls-contentEditable"
                placeholder={t("image_settings.description_placeholder")}
              />
            </S.DescText>
          )}
        </Field>
      </li>
      <li>
        <Field
          name={`${blockId}.tool-10.size`}
          defaultValue={"default"}
          className="selectField"
        >
          {(props) => (
            <S.Field>
              <label>
                <T id="image_settings.image_size" />
              </label>
              <Dropdown
                name={props.input.name}
                value={props.input.value}
                onChange={(e, data) => {
                  props.input.onChange(data.value);
                  if (data.value === "percent") {
                    height.onChange(100);
                    width.onChange(100);
                  } else {
                    height.onChange(300);
                    width.onChange(300);
                  }
                }}
                fluid
                selection
                options={sizeOptions}
              />
            </S.Field>
          )}
        </Field>
      </li>

      {size.value && size.value !== "default" && (
        <>
          <li>
            <Field name={`${blockId}.tool-10.height`} defaultValue={300}>
              {(props) => (
                <S.Field>
                  <label style={{ marginRight: 16 }}>
                    <T id="image_settings.height" />
                  </label>
                  <Input
                    type="number"
                    name={props.input.name}
                    value={props.input.value}
                    onChange={(e, data) => {
                      props.input.onChange(data.value);
                    }}
                    min={0}
                  />
                </S.Field>
              )}
            </Field>
          </li>

          <li>
            <Field name={`${blockId}.tool-10.width`} defaultValue={300}>
              {(props) => (
                <S.Field>
                  <label style={{ marginRight: 16 }}>
                    <T id="image_settings.width" />
                  </label>
                  <Input
                    type="number"
                    name={props.input.name}
                    value={props.input.value}
                    min={0}
                    onChange={(e, data) => {
                      props.input.onChange(data.value);
                    }}
                  />
                </S.Field>
              )}
            </Field>
          </li>
        </>
      )}
    </S.SectionWrapper>
  );
};
