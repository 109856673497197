import { observer } from "mobx-react-lite";
import { Form } from "react-final-form";
import { FieldText } from "ui/FieldText";
import { GeneralEdit } from "ui/common-styles";
import { FieldCheckbox } from "ui/FieldCheckbox";
import { PanelActions } from "../../PanelActions";

import { T } from "util/Translation";
import { useTranslation } from "react-i18next";

export const Email = observer(({ project, updateSettings }) => {
  const { t } = useTranslation();
  const projectId = project.id;
  const projectSettings = project.settings;

  const onSubmit = async (data) => {
    await updateSettings(projectId, data);
  };

  return (
    <GeneralEdit>
      <h4>
        <T id="email_notification.title" />
      </h4>
      <p>
        <T id="email_notification.description" />
      </p>

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <ul>
                <li>
                  <FieldCheckbox
                    finalFormProps={{
                      name: "use_reply_email",
                      initialValue: projectSettings.use_reply_email,
                    }}
                    semanticProps={{
                      label: t("email_notification.send_email"),
                      disabled: submitting,
                    }}
                    initialChecked={projectSettings.use_reply_email}
                  />
                </li>
                {values.use_reply_email && (
                  <li>
                    <FieldText
                      label={t("email_notification.email_label")}
                      finalFormProps={{
                        name: "reply_email",
                        initialValue: projectSettings.reply_email,
                      }}
                      semanticProps={{
                        placeholder: t("email_notification.email_placeholder"),
                        disabled: submitting,
                      }}
                    />
                  </li>
                )}
              </ul>

              <PanelActions />
            </form>
          );
        }}
      />
    </GeneralEdit>
  );
});
