import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  flex-direction: column;
  display: flex;
  align-items: center;
  background: rgb(252 245 234);
`;

export const AppWrapper = styled.div`
  .ui.floating.dropdown > .menu {
    border-radius: 8px !important;
    box-shadow: var(--shadow-400) !important;
    border: 1px solid var(--grey-200);
    overflow: hidden;

    .item {
      display: flex;
      align-items: center;
      gap: 8px;
      text-decoration: none;
      color: var(--grey-700);
      fill: var(--grey-700);
      text-transform: capitalize;

      :hover {
        background: var(--grey-100);
        color: var(--grey-800);
        fill: var(--grey-800);
      }
    }
  }

  .ui.selection.dropdown {
    border: 1px solid var(--grey-300);

    > .dropdown.icon {
      height: 42px;
    }

    :hover {
      border-color: var(--grey-400);
    }

    &.active {
      border-color: var(--grey-500);
      .menu {
        border-color: var(--grey-500);
      }
    }
  }

  .ui.checkbox {
    min-height: 32px;
    display: flex;
    align-items: center;

    label {
      min-height: 17px;
      line-height: 24px;
    }
  }

  .ui.checkbox input ~ .box:after,
  .ui.checkbox input ~ label:after {
    color: var(--white);
    font-size: 14px;
    margin: -1px 3px;
  }

  .ui.checkbox input:checked ~ .box:before,
  .ui.checkbox input:checked ~ label:before {
    border-color: var(--primary-500);
  }

  .ui.checkbox:not(.radio) input:checked ~ .box:before,
  .ui.checkbox:not(.radio) input:checked ~ label:before {
    background-color: var(--primary-500);
  }

  .ui.checkbox:not(.radio) input:checked:focus ~ .box:after,
  .ui.checkbox:not(.radio) input:checked:focus ~ label:after {
    color: var(--white);
  }
  .ui.radio.checkbox input:focus:checked ~ .box:after,
  .ui.radio.checkbox input:focus:checked ~ label:after {
    background-color: var(--primary-500);
  }

  .ui.radio.checkbox input ~ .box:after,
  .ui.radio.checkbox input ~ label:after {
    background-color: var(--primary-500);
    width: 20px;
    height: 20px;
    margin: 0px;
  }

  .ui.checkbox input ~ .box:before,
  .ui.checkbox input ~ label:before {
    background: #fff;
    border-color: var(--grey-300);
    height: 22px;
    width: 22px;
  }

  .ui.checkbox:hover input ~ .box:before,
  .ui.checkbox:hover input ~ .label:before {
    border-color: var(--grey-400);
  }

  .ui.radio.checkbox input ~ .box:before,
  .ui.radio.checkbox input ~ label:before {
    height: 20px;
    width: 20px;
    margin: 0;
  }

  .ui.toggle.checkbox .box,
  .ui.toggle.checkbox label {
    padding-left: 52px;

    :before {
      background: var(--grey-300);
      width: 40px;
      margin: 2px 0;
    }

    :after {
      margin: 4px 3px;
    }
  }

  .ui.toggle.checkbox input:focus ~ .box:before,
  .ui.toggle.checkbox input:focus ~ label:before {
    background: var(--grey-300);
  }

  .ui.toggle.checkbox input ~ .box:after,
  .ui.toggle.checkbox input ~ label:after {
    box-shadow: none;
    height: 18px;
    width: 18px;
  }

  .ui.toggle.checkbox input:checked ~ .box:after,
  .ui.toggle.checkbox input:checked ~ label:after {
    left: 17px;
  }
`;
