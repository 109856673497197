import styled from "styled-components";

export const FieldsSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 416px;

  ul {
    list-style-type: none;
    padding-inline-start: 0;
    margin: 0;

    li {
      display: grid;
      grid-template-columns: 160px 1fr;
      gap: 8px 16px;
      align-items: start;
      padding: 16px 0;
      border-bottom: 1px solid var(--grey-200);
      width: 416px;

      label {
        color: var(--grey-800);
      }

      label + div {
        margin: 0;
      }

      p {
        color: var(--grey-600);
        text-wrap: pretty;
        max-width: 440px;
      }
    }
  }

  .ls-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
`;

export const AvatarSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px 32px;
  min-width: 240px;
  max-width: 416px;
  background: var(--grey-50);
  border: 1px solid var(--grey-200);

  img,
  .ls-avatar {
    height: 200px;
    width: 200px;
    border-radius: 100%;
    box-shadow: var(--shadow-200);
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 100px;
    background: var(--secondary-500);
    color: var(--secondary-50);
    object-fit: cover;
  }

  .ls-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    > div {
      margin-bottom: 0px;
    }
  }
`;

export const SubscriptionCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  min-width: 240px;
  padding: 8px 16px;
  ${({ subscription }) => {
    if (subscription === 1)
      return `
  background: var(--grey-200);
  >p {
  color: var(--grey-600) !important;
  }
  `;
    if (subscription === 2)
      return `
  background: var(--tertiary-200);
  >p {
  color: var(--tertiary-900) !important;
  }
  `;
    if (subscription === 3)
      return `
  background: var(--secondary-200);
  >p {
  color: var(--secondary-900) !important;
  }
  `;
  }}

  .subscription-type {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
  }
`;
